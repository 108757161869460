import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
// import BootstrapVue3 from "bootstrap-vue-3";
// axios.defaults.baseURL = "http://localhost:3001/api";
axios.defaults.baseURL = "https://leaqo.com/api";
const app = createApp(App);

// Make BootstrapVue3 available throughout your project
// app.use(BootstrapVue3);

app.use(router).mount("#app");
