<template>
    <div>
        <div class="css-rvolo9" v-if="type == 'one'">
            <span class="css-koje2">
                <span class="css-1nf8kel">
                    <div :class="{
                        'css-1hoyw9x': current_value >= 1 && current_value <= 3,
                        'css-tw54mm': current_value >= 4 && current_value <= 6,
                        'css-27d4h4': current_value >= 7 && current_value <= 8,
                        'css-1h78rdl': current_value >= 9
                    }">{{ current_value }}</div>
                    <div class="css-7zr6s4">{{ levelTextCurrent }}</div>
                </span>
            </span>
        </div>
        <div class="css-rvolo9" v-else-if="type == 'multi'">

            <span class="css-koje2">
                <span class="css-1nf8kel">
                    <div :class="{
                        'css-1hoyw9x': min >= 1 && min <= 3,
                        'css-tw54mm': min >= 4 && min <= 6,
                        'css-27d4h4': min >= 7 && min <= 8,
                        'css-1h78rdl': min >= 9
                    }">
                        {{ min }}
                    </div>
                    <div class="css-7zr6s4">{{ levelTextMin }}</div>
                </span>
            </span>
            <span class="css-f998fu" v-if="min != max">-</span>
            <span class="css-koje2" v-if="min != max">
                <span class="css-1nf8kel">
                    <div :class="{
                        'css-1hoyw9x': max >= 1 && max <= 3,
                        'css-tw54mm': max >= 4 && max <= 6,
                        'css-27d4h4': max >= 7 && max <= 8,
                        'css-1h78rdl': max >= 9
                    }">{{ max }}</div>
                    <div class="css-7zr6s4">{{ levelTextMax }}</div>
                    <!-- {{ type }} -->
                </span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['max', 'min', 'type', 'current_value'],

    computed: {
        levelTextMin() {
            if (this.min >= 1 && this.min <= 3) {
                return 'Beginner';
            } else if (this.min >= 4 && this.min <= 6) {
                return 'Intermediate';
            } else if (this.min >= 7 && this.min <= 8) {
                return 'Advanced';
            } else if (this.min >= 9) {
                return 'Proficient';
            } else {
                return '';
            }
        },
        levelTextMax() {
            if (this.max >= 1 && this.max <= 3) {
                return 'Beginner';
            } else if (this.max >= 4 && this.max <= 6) {
                return 'Intermediate';
            } else if (this.max >= 7 && this.max <= 8) {
                return 'Advanced';
            } else if (this.max >= 9) {
                return 'Proficient';
            } else {
                return '';
            }
        }, levelTextCurrent() {
            if (this.current_value >= 1 && this.current_value <= 3) {
                return 'Beginner';
            } else if (this.current_value >= 4 && this.current_value <= 6) {
                return 'Intermediate';
            } else if (this.current_value >= 7 && this.current_value <= 8) {
                return 'Advanced';
            } else if (this.current_value >= 9) {
                return 'Proficient';
            } else {
                return '';
            }
        }

    }


}
</script>

<style lang="css" scoped>
.css-f998fu {
    color: var(--kf-color-grey-900);
    font-size: 12px;
    margin: 0px 10px;
    vertical-align: middle;
}

.css-koje2 {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
}

.css-7zr6s4 {
    display: block;
    font-weight: 600;
    margin-left: 0.5em;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}

.css-1hoyw9x {
    color: var(--kf-color-white);
    font-weight: 700;
    line-height: 1;
    padding: 0.25em 0.5em;
    background-color: rgb(0, 157, 255);
}

.css-tw54mm {
    color: var(--kf-color-white);
    font-weight: 700;
    line-height: 1;
    padding: 0.25em 0.5em;
    background-color: rgb(169, 217, 72);
}

.css-27d4h4 {
    color: var(--kf-color-white);
    font-weight: 700;
    line-height: 1;
    padding: 0.25em 0.5em;
    background-color: rgb(255, 115, 0);
}

.css-1h78rdl {
    color: var(--kf-color-white);
    font-weight: 700;
    line-height: 1;
    padding: 0.25em 0.5em;
    background-color: rgb(255, 54, 82);
}

.css-rvolo9 {
    margin: calc(5px) 0px;
}

.css-1nf8kel {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    color: var(--kf-color-grey-900);
}
</style>