<!-- TitleSection.vue -->
<template>
    <div>
        <!-- Your template for TitleSection -->
        <!-- <p>Media Section with ID: {{ section.id }}</p> -->
        <div>
            <div>
                <figure class="css-o667g3">
                    <div class="css-qqlvpa">
                        <div class="css-jgvdrf">
                            <div class="css-1rj1g7w">
                                <div class="css-tfgvah">
                                    <div class="css-chzjiw">
                                        <div class="css-1q4757b">
                                            <div class="css-nyr3n3">
                                                <button type="button" class="css-4f999p" data-testid="lesson-image"
                                                    aria-label="Expand image" style="outline: none;">
                                                    <div class="css-1rux0er">
                                                        <img alt="" class="css-1hgzx0m"
                                                            :src="section.media_entries[0].image.url" loading="lazy">
                                                    </div>
                                                    <div class="css-3sr8wx" data-testid="info-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"
                                                            width="100%" height="100%" aria-hidden="true">
                                                            <path fill="var(--kf-color-white)"
                                                                d="M7.5,0C3.4,0,0,3.4,0,7.5C0,11.6,3.4,15,7.5,15c4.1,0,7.5-3.4,7.5-7.5C15,3.4,11.6,0,7.5,0z M8.4,12.6H6.6v-7 h1.8V12.6z M7.5,4.4c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1C8.5,4,8.1,4.4,7.5,4.4z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </figure>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section'],
};
</script>

<style scoped>
.css-o667g3 {
    margin: 1em 0px;
    text-align: center;
}

.css-qqlvpa {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-around;
    -webkit-box-align: center;
    align-items: center;
}

.css-jgvdrf {
    margin: 0.5em;
}

.css-1rj1g7w {
    background-color: white;
    padding: 4px;
    margin: 0px -4px;
    border-radius: 3px;
}

.css-tfgvah {
    padding: 15px 25px;
    border-radius: 3px;
    font-size: 14px;
    background-color: var(--kf-color-grey-50);
}

.css-chzjiw {
    display: flex;
    flex-direction: column;
    gap: var(--kf-static-space-s);
}

.css-1q4757b {
    display: flex;
    margin: 0px auto;
    max-width: 300px;
}

.css-1hgzx0m {
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 256px;
    aspect-ratio: 1088 / 816;
    border-radius: 3px;
    display: block;
}

.css-3sr8wx {
    position: absolute;
    width: var(--kf-static-size-100);
    height: var(--kf-static-size-100);
    line-height: var(--kf-static-size-100);
    padding: var(--kf-static-space-xxxs);
    bottom: 3%;
    right: 3%;
    opacity: 0.5;
    filter: drop-shadow(rgb(184, 184, 184) 0px 0px 2px);
}
</style>