<template>
    <div>

        <!-- <p>Article Section with ID: {{ section.id }}</p> -->
        <div>
            <div>
                <div class="css-13cnam7">
                    <div v-if="section.combined_audio" class="css-mvj3r8">
                        <div class="css-okvds5">
                            <div class="css-1xdhyk6" data-mouseover-dictionary-disabled="">
                                <audio preload="none"
                                    src="https://assets.app.engoo.com/organizations/5d2656f1-9162-461d-88c7-b2505623d8cb/sounds/7RYDxwfaxDQg13szAonmwq.mpga">
                                </audio>
                                <div class="css-17hxku5">
                                    <button type="button" aria-label="Play" class="css-19oogw2" aria-disabled="false"
                                        style="outline: none;">
                                        <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em"
                                            aria-hidden="true">
                                            <g clip-path="url(#:rs:)">
                                                <path fill="currentColor" fill-rule="evenodd"
                                                    d="M21 12.41c.01.27-.2.59-.64.94l-15.53 9.1c-.69.48-1.17.64-1.45.5-.27-.14-.4-.61-.37-1.42V3.23c-.05-.63.07-1.01.37-1.16.31-.16.74-.04 1.3.33l15.6 9.26c.47.23.7.48.72.75Z"
                                                    clip-rule="evenodd">
                                                </path>
                                            </g>
                                            <defs>
                                                <clipPath id=":rs:">
                                                    <path fill="#fff" d="M3 2h18v21H3z">

                                                    </path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </button>
                                    <div class="css-11bl4u1">
                                        <span class="css-1b2doo2">
                                            <span class="css-1d0tddh">AI
                                                Generated Audio</span>
                                        </span>
                                        <span class="css-10dzpei">01:56 /
                                            01:56</span>
                                    </div>
                                    <div class="css-o094zh">
                                        <div class="css-19jiqxp">

                                        </div>
                                        <div class="css-132wwiy">
                                            <div class="css-12skv5w">
                                                <div class="css-1449hq0" style="width: 100%;">
                                                </div>
                                            </div>
                                            <div class="css-1nqdncr" role="slider" aria-label="Seek Slider"
                                                aria-valuemin="0" aria-valuemax="116.297125" aria-valuenow="116.297125"
                                                aria-valuetext="100%" tabindex="0"
                                                style="left: calc(100% - (var(--kf-static-size-100) / 2));">
                                            </div>
                                        </div>
                                        <div class="css-bhgt1g">

                                        </div>
                                    </div>
                                    <div class="css-1wb42ck">
                                        <button type="button" aria-label="Skip back 10 seconds" class="css-759w0h"
                                            aria-disabled="false" style="outline: none;">
                                            <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em"
                                                aria-hidden="true">
                                                <g clip-path="url(#:rj:)">
                                                    <path fill="currentColor"
                                                        d="M11.99 5V1l-5 5 5 5V7a6 6 0 1 1-6 6h-2a8 8 0 1 0 8-8Zm-1.1 11h-.85v-3.26l-1.01.31v-.69l1.77-.63h.09V16Zm4.28-1.76c0 .32-.03.6-.1.82a1.8 1.8 0 0 1-.29.57c-.12.15-.28.26-.45.33-.17.07-.37.1-.59.1-.22 0-.41-.03-.59-.1a1.15 1.15 0 0 1-.46-.33 1.54 1.54 0 0 1-.3-.57 2.8 2.8 0 0 1-.11-.82v-.74c0-.32.03-.6.1-.82.07-.22.17-.42.29-.57.12-.15.28-.26.45-.33.17-.07.37-.1.59-.1.22 0 .41.03.59.1s.33.18.46.33c.13.15.23.34.3.57.07.23.11.5.11.82v.74Zm-.85-.86c0-.19-.01-.35-.04-.48a1 1 0 0 0-.12-.31.39.39 0 0 0-.19-.17.66.66 0 0 0-.5 0 .44.44 0 0 0-.19.17 1 1 0 0 0-.12.31 2.2 2.2 0 0 0-.04.48v.97c0 .19.01.35.04.48s.07.24.12.32c.05.08.11.14.19.17a.66.66 0 0 0 .5 0 .44.44 0 0 0 .19-.17.86.86 0 0 0 .11-.32c.02-.13.04-.29.04-.48v-.97h.01Z">
                                                    </path>
                                                </g>
                                                <defs>
                                                    <clipPath id=":rj:">
                                                        <path fill="#fff" d="M0 0h24v24H0z">

                                                        </path>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </button>
                                        <div class="css-gvm388">
                                            <button type="button" aria-label="Playback Speed (Normal)"
                                                class="css-f1cr0l" tabindex="0" aria-haspopup="listbox"
                                                aria-expanded="false">1x</button>
                                        </div>
                                        <button type="button" aria-label="Extra Tools" aria-expanded="false"
                                            class="css-wnti1i" aria-disabled="false" style="outline: none;">
                                            <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em"
                                                aria-hidden="true">
                                                <circle cx="4" cy="12" r="2.5" fill="currentColor">

                                                </circle>
                                                <circle cx="12" cy="12" r="2.5" fill="currentColor">

                                                </circle>
                                                <circle cx="20" cy="12" r="2.5" fill="currentColor">

                                                </circle>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="css-19m2fbm">
                        <div class="css-nbcxml">
                            <div class="css-h773az">
                                <div class="css-1sf241b">
                                    <span class="css-0">
                                        <span class="css-l5xv05">
                                            <span class="css-17poqz3" lang="en">
                                                <span v-if="section.title_text">{{ section.title_text.text }}</span>
                                            </span>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>



                        <div class="css-h773az" v-for="(paragraph, index) in section.paragraphs" :key="index">
                            <span class="css-0" v-if="paragraph.title_text">
                                <div class="css-1sw3sz9">
                                    <span class="css-l5xv05">
                                        <span class="css-17poqz3" lang="en">
                                            <span>{{ paragraph.title_text.text }}</span>
                                        </span>
                                    </span>
                                </div>
                            </span>
                            <div v-if="paragraph.paragraph_style == 'Plain'">
                                <div v-for="(paragraph_sentence, index) in paragraph.paragraph_sentences" :key="index">
                                    <p class="css-54q8sn">
                                        <span class="css-0">
                                            <span class="css-l5xv05">
                                                <span class="css-17poqz3" lang="en">

                                                    <span>{{ paragraph_sentence.text.text }}</span>
                                                </span>
                                            </span>
                                        </span>

                                    </p>
                                    <div class="css-h773az">

                                    </div>
                                </div>

                            </div>
                            <div v-else-if="paragraph.paragraph_style == 'UnorderedList'">
                                <div v-for="(paragraph_sentence, index) in paragraph.paragraph_sentences" :key="index">
                                    <ul>
                                        <li>
                                            <span class="css-0">
                                                <span class="css-l5xv05">
                                                    <span class="css-17poqz3" lang="en">
                                                        <span>
                                                            {{ paragraph_sentence.text.text }}
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>

                                    <div class="css-h773az">

                                    </div>
                                </div>

                            </div>
                            <div v-else-if="paragraph.paragraph_style == 'Expressions'">
                                <div v-for="(paragraph_sentence, index) in paragraph.paragraph_sentences" :key="index">
                                    <p class="css-54q8sn">
                                        <span class="css-0">
                                            <span class="css-l5xv05">
                                                <span class="css-17poqz3" lang="en">

                                                    <span>{{ paragraph_sentence.text.text }}</span>
                                                </span>
                                            </span>
                                        </span>

                                    </p>
                                    <div class="css-h773az">

                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: ['section', 'references'],
    data() {
        return {
            // references_text: ''
        }
    }

};
</script>


<style scoped>
.css-mvj3r8 {
    position: sticky;
    top: 0px;
    z-index: 1;
}

.css-h773az {
    margin-bottom: 1.5em;
}

.css-54q8sn {
    margin: 0px 0px 1.5em;
}

.css-13cnam7 {
    isolation: isolate;
}

.css-1sf241b {
    line-height: 1;
    margin: 10px 0px;
    font-weight: 600;
    font-size: 20px;
}

.css-1sw3sz9 {
    line-height: 1;
    margin: 10px 0px;
    font-weight: normal;
    font-size: 18px;
}

.css-19oogw2 {
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    text-align: center;
    appearance: none;
    position: relative;
    cursor: pointer;
    opacity: 1;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: var(--kf-static-size-250);
    height: var(--kf-static-size-250);
    font-size: var(--kf-static-size-150);
    border-radius: var(--kf-static-size-25);
    color: var(--kf-color-blue-400);
    grid-area: play;
}

.css-11bl4u1 {
    grid-area: message;
    display: grid;
    gap: var(--kf-static-space-xs);
    grid-template-columns: 1fr auto;
}

.css-10dkbkl {
    display: flex;
    height: var(--kf-static-size-100);
    -webkit-box-align: center;
    align-items: center;
    cursor: default;
    grid-area: seekbar;
}

.css-1wb42ck {
    display: flex;
    gap: var(--kf-static-space-xxs);
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: nowrap;
    grid-area: buttons;
}

.css-17hxku5 {
    font-family: 'Open Sans', 'Eikaiwa Icons', sans-serif;
    font-size: var(--kf-font-detail-size-s);
    font-style: var(--kf-font-style-default);
    font-weight: var(--kf-font-detail-weight-strong);
    letter-spacing: var(--kf-font-letter-spacing-default);
    line-height: var(--kf-font-detail-line-height);
    display: grid;
    /* column-gap: ; */
    -webkit-box-align: center;
    align-items: center;
    background-color: var(--kf-color-white);
    color: #404040;
    text-align: left;
    row-gap: #ffffff;
    grid-template-columns: auto minmax(0px, 1fr) auto;
    grid-template-rows: var(--kf-static-size-125) auto var(--kf-static-size-125);
    grid-template-areas:
        "play . buttons"
        "play seekbar buttons"
        "play message buttons";
    padding: var(--kf-static-space-s);
}

.css-dw6t8i {
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    text-align: center;
    appearance: none;
    position: relative;
    cursor: default;
    opacity: 0.5;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: var(--kf-static-size-250);
    height: var(--kf-static-size-250);
    font-size: var(--kf-static-size-150);
    border-radius: var(--kf-static-size-25);
    color: var(--kf-color-grey-600);
}

.css-f1cr0l {
    border: none;
    margin: 0px;
    text-decoration: none;
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    cursor: pointer;
    text-align: center;
    appearance: none;
    font-family: var(--kf-font-family-default);
    font-size: var(--kf-font-detail-size-s);
    font-style: var(--kf-font-style-default);
    letter-spacing: var(--kf-font-letter-spacing-default);
    line-height: var(--kf-font-detail-line-height);
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: var(--kf-static-size-300);
    height: var(--kf-static-size-200);
    color: var(--kf-color-grey-900);
    font-weight: var(--kf-font-weight-semi-bold);
    padding: var(--kf-static-space-xxs) var(--kf-static-space-s);
    border-radius: var(--kf-static-size-50);
    background-color: var(--kf-color-grey-75);
    user-select: none;
}

.css-wnti1i {
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    font-family: inherit;
    text-align: center;
    appearance: none;
    position: relative;
    cursor: pointer;
    opacity: 1;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: var(--kf-static-size-250);
    height: var(--kf-static-size-250);
    border-radius: var(--kf-static-size-25);
    color: var(--kf-color-grey-600);
    font-size: var(--kf-static-size-125);
}

.css-okvds5 {
    background-color: var(--kf-color-white);
    border-color: var(--kf-color-grey-100);
    box-shadow: var(--kf-shadow-none);
    border-radius: var(--kf-static-size-0);
    padding: var(--kf-static-space-0);
    border-width: var(--kf-static-size-5) var(--kf-static-size-0);
    border-style: solid;
    overflow: hidden;
    margin-block: var(--kf-static-space-xl);
    margin-inline: calc(-40px);
    padding-inline: 18px;
}

.css-12skv5w {
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 4px;
    background-color: #F2F2F2;
}
</style>