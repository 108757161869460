<template>
    <div class="css-19haa99">
        <div class="css-12xq6zq" style="max-width: 1200px;">
            <section class="css-1asjhqm">
                <div class="css-fhrr3c">
                    <div class="css-kp3nri">
                        <div class="css-1h42yi0">
                            <section class="css-8atqhb">
                                <header class="css-1pkqnxz">
                                    <div class="css-1ubfjd8">
                                        <div class="css-1oirenq">
                                            <div class="css-hc8vmp">
                                                <div class="css-1526ib8">

                                                    <img v-if="cat_details && cat_details.image" alt=""
                                                        class="css-118agvy" :src="cat_details.image.url" loading="lazy">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="css-8e7ofr">
                                            <div class="css-rvolo9">
                                                <hgroup>
                                                    <span class="css-l5xv05">
                                                        <span class="css-17poqz3" lang="en">
                                                            <h2 class="css-1poyolx">
                                                                <span v-if="cat_details && cat_details.name_text">{{
                                                                    cat_details.name_text.text }}</span>
                                                            </h2>
                                                        </span>
                                                    </span>
                                                    <span class="css-17poqz3"></span>
                                                </hgroup>
                                            </div>
                                            <levelPart v-if="cat_details && cat_details.name_text"
                                                :min="cat_details.target_level_range.min"
                                                :max="cat_details.target_level_range.max" type="multi" />
                                            <div class="css-rvolo9">
                                                <div class="css-lqm1wb">
                                                    <span class="css-17poqz3" lang="en">
                                                        <span v-if="cat_details && cat_details.description_text">{{
                                                            cat_details.description_text.text }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="css-rvolo9"></div>
                                        </div>
                                    </div>
                                </header>
                                <div v-for="course in courses" :key="course.id" class="css-6uwg9e">
                                    <section class="css-1w0zr0c" :id="'course-' + course.id">
                                        <header class="css-91djnn">
                                            <div></div>
                                            <div class="css-xm240n">
                                                <div class="css-1tsj30g">
                                                    <div class="css-1uph9wa">
                                                        <div class="css-1uk7dtp">
                                                            <hgroup>
                                                                <span class="css-l5xv05">
                                                                    <span class="css-17poqz3" lang="en">
                                                                        <h3 class="css-1bhrrvo">
                                                                            <span>{{ course.name_text.text }}</span>
                                                                        </h3>
                                                                    </span>
                                                                </span>
                                                                <span class="css-17poqz3"></span>
                                                            </hgroup>
                                                        </div>
                                                        <div class="css-1uk7dtp">
                                                            <span class="css-17poqz3" lang="en">
                                                                <div class="css-lqm1wb">
                                                                    <span>{{ course.description_text.text }}</span>
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </header>
                                        <div v-for="(lesson, index) in course.lessons" :key="lesson.id"
                                            class="css-13ckoan">
                                            <!-- <a :href="lesson.link" > -->
                                            <RouterLink class="css-1fdxz4i" :socket="socket"
                                                :currentRoomId="currentRoomId" :userId="userId"
                                                :to="getLink(lesson.master_id)">
                                                <div class="css-sdqa5n">
                                                    <div class="css-ljjwvb">
                                                        <div class="css-1rrfs5c">
                                                            <h4 class="css-nr8xls">Lesson {{ index + 1 }}</h4>
                                                            <span class="css-ioum7v">
                                                                <span class="css-1vg6q84">
                                                                    <span class="css-l5xv05">
                                                                        <span class="css-17poqz3" lang="en">
                                                                            <span>{{ lesson.title_text.text }}</span>
                                                                        </span>
                                                                    </span>
                                                                    <span class="css-l5xv05">
                                                                        <span class="css-17poqz3" lang="en"></span>
                                                                    </span>
                                                                </span>
                                                                <span class="css-1y053wt">
                                                                    <span class="css-17poqz3">
                                                                        <span></span>
                                                                    </span>
                                                                    <span class="css-17poqz3"></span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="css-99w7dx">
                                                        <div class="css-19fmpbz"></div>
                                                        <levelPart v-if="cat_details && cat_details.name_text"
                                                            type="one" :current_value="lesson.content_level" />
                                                        <div class="css-rrm59m"><svg fill="none" viewBox="0 0 24 24"
                                                                width="1em" height="1em" class="css-10451n3">
                                                                <g clip-path="url(#:r9:)">
                                                                    <path fill="currentColor"
                                                                        d="M18.45 11.54a.6.6 0 0 1 0 .92L5.14 23.64a.6.6 0 0 1-.99-.46V.82c0-.51.6-.8.99-.46l13.31 11.18Z">
                                                                    </path>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id=":r9:">
                                                                        <path fill="#fff" d="M4 0h15v24H4z"></path>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg></div>
                                                    </div>

                                                </div>
                                            </RouterLink>
                                        </div>
                                        <button type="button" class="css-l3wv92" style="outline: none;"
                                            @click="fetchLessons(course.id)">
                                            <span aria-hidden="true" class="css-1ea1agg">
                                                <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em"
                                                    class="css-10451n3">
                                                    <g clip-path="url(#:r1a:)">
                                                        <path fill="currentColo   r"
                                                            d="M12.46 18.45a.6.6 0 0 1-.92 0L.36 5.14a.6.6 0 0 1 .46-.99h22.36c.51 0 .8.6.46.99L12.46 18.45Z">
                                                        </path>
                                                    </g>
                                                    <defs>
                                                        <clipPath id=":r1a:">
                                                            <path fill="#fff" d="M24 4v15H0V4z"></path>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                            <span>
                                                <span>Show more</span>
                                            </span>
                                        </button>
                                    </section>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

</template>

<script>
import axios from 'axios';
import levelPart from '../../components/study_room/small/levelPart.vue';

export default {

    data() {
        return {
            cat_id: this.$route.params.id || null,
            courses: [],
            cat_details: {}
        };
    },
    components: {
        levelPart
    },
    created() {
        if (this.cat_id) {

            this.fetchCat();
            this.fetchCourses();
        } else {
            console.error('No category ID provided.');
        }
    },
    computed: {
        shouldShowLessonHeader() {
            return this.$route.path.includes('study-room');
        }
    },
    methods: {
        getLink(masterId) {
            return this.shouldShowLessonHeader ? `/study-room/lesson-view/${masterId}` : `/lesson-view/${masterId}`;
        },
        async fetchCourses() {
            try {
                const response = await axios.get(`courses/${this.cat_id}`);
                this.courses = response.data;

                // Fetch lessons for each course
                this.courses.forEach(course => {
                    this.fetchLessons(course.id);
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },

        async fetchCat() {
            try {
                const response = await axios.get(`categories/${this.cat_id}`);
                this.cat_details = response.data;

            } catch (error) {
                console.error('Error fetching lessons:', error);
            }
        }
        ,
        async fetchLessons(course_id) {
            try {
                const response = await axios.get(`lesson_headers/${course_id}`);
                const lessons = response.data;

                // Find the course and assign lessons to it
                const course = this.courses.find(course => course.id === course_id);
                if (course) {
                    course.lessons = lessons;
                }
            } catch (error) {
                console.error('Error fetching lessons:', error);
            }
        }


    }
};
</script>



<style scoped>
.css-1pkqnxz {
    background-color: var(--kf-color-white);
    border-radius: 2px;
    box-shadow: var(--kf-shadow-s);
    padding: 15px;
    flex: 1 1 auto;
}

.css-6uwg9e {
    margin: 20px 0px;
}

.css-1pkqnxz {
    background-color: var(--kf-color-white);
    border-radius: 2px;
    box-shadow: var(--kf-shadow-s);
    padding: 15px;
    flex: 1 1 auto;
}

.css-1ubfjd8 {
    display: flex;
    flex: 1 1 auto;
    padding: 30px 15px 30px 30px;
}

.css-1oirenq {
    margin: 0px 30px 0px 0px;
    min-width: 140px;
    max-width: 140px;
}

.css-8e7ofr {
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-flow: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin: calc(-5px) 0px;
}

.css-rvolo9 {
    margin: calc(5px) 0px;
}

.css-118agvy {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.css-koje2 {
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
}



.css-7zr6s4 {
    display: block;
    font-weight: 600;
    margin-left: 0.5em;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
}

.css-1nf8kel {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    color: var(--kf-color-grey-900);
}





.css-91djnn {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    padding: 20px 15px 20px 30px;
}

.css-13ckoan {
    border-top: 1px solid var(--kf-color-grey-75);
}

.css-sdqa5n:hover {
    background-color: #eeeded;
}

.css-sdqa5n {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 10px 15px 10px 30px;

    border-top: 1px solid #F2F2F2;

}

.css-1rrfs5c {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: 14px;
    letter-spacing: 0.8px;
}

.css-1w0zr0c {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    flex: 1 1 auto;
}

.css-q7d39h {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 12px;
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-left: 15px;
}

.css-nr8xls {
    color: var(--kf-color-grey-600);
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    display: inline;
    font-weight: normal;
    margin: 0px 15px 0px 0px;
}

.css-1hoyw9x {
    color: var(--kf-color-white);
    font-weight: 700;
    line-height: 1;
    padding: 0.25em 0.5em;
    background-color: rgb(0, 157, 255);
}

.css-13ckoan {
    border-top: 1px solid #F2F2F2;
}

.css-l3wv92 {
    display: block;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-image: initial;
    padding: 15px 15px 15px 30px;
    margin: 0px;
    text-decoration: none;
    background-image: initial;
    background-position: initial;
    background-size: initial;
    background-repeat: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    color: var(--kf-color-grey-900);
    font-family: inherit;
    font-size: 12px;
    cursor: pointer;
    text-align: right;
    appearance: none;
    background-color: inherit;
    border-top: 1px solid var(--kf-color-grey-75);
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 1;
    width: 100%;
}

.css-lqm1wb {
    color: #404040;
    font-size: 16px;
    letter-spacing: 0.8px;
}

.css-1pkqnxz {
    background-color: white;
    border-radius: 2px;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
    padding: 15px;
    flex: 1 1 auto;
}

.css-19haa99 {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 60px 5px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    background: rgb(242, 242, 242);
    font-family: "Open Sans", "Eikaiwa Icons", sans-serif;
}

.css-ioum7v {
    word-break: break-word;
    overflow-wrap: anywhere;
    color: var(--kf-color-grey-900);
}

.css-1vg6q84 {
    font-weight: 700;
}


.css-99w7dx {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 0;
    gap: var(--kf-static-space-m);
    font-size: var(--kf-font-size-50);
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-left: 15px;
}

.css-10451n3 {
    font-size: 0.7em;
    color: var(--kf-color-grey-900);
}

.css-1fdxz4i:hover {
    background-color: #f9f9f9
}
</style>