<template>
    <div class="navbar">
        <input type="text" placeholder="Search" />
        <div class="navbar-right">
            <span>Notifications</span>
            <span>Profile</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DashboardNavbar'
}
</script>

<style scoped>
.navbar {
    height: 60px;
    background-color: #ecf0f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.navbar-right {
    display: flex;
    gap: 20px;
}
</style>