<template>
    <main id="content" class="css-12xwatn">
        <div class="css-i9gxme">
            <div>
                <div class="css-197ofbd">
                    <div class="css-f1szgx" lang="en">
                        <div class="css-0">
                            <div class="css-l5xv05" data-testid="render-wrapper">
                                <div class="css-1utcz1n">
                                    <Lesson_Header :title_text="Lessons.title_text" :image="Lessons.image">
                                    </Lesson_Header>

                                    <div v-if="Lessons">
                                        <!-- {{ Lessons }} -->
                                        <div v-for="(exercise, index) in Lessons.exercises" :key="index"
                                            :id="'windowexercise-' + index" class="css-3g0s26">
                                            <div class="css-moa3jd">
                                                <div class="css-1xdhyk6">
                                                    <div class="css-1fo460h" data-mouseover-dictionary-disabled="">
                                                        <div class="css-5tu2hh">
                                                            <a href="/app/lessons/conversation-getting-started-nice-to-meet-you/A9ifpKjTEem88AM3N2qEcQ?category_id=4g-usmSuEeirTddASFJXxg&amp;course_id=Prw1rO-JEemu5uddujZ5YA#exercise-1"
                                                                class="css-nn640c">Exercise {{ index + 1 }}</a>
                                                        </div>
                                                        <div>
                                                            <span class="css-14lp8wm">
                                                                <a href="/app/lessons/conversation-getting-started-nice-to-meet-you/A9ifpKjTEem88AM3N2qEcQ?category_id=4g-usmSuEeirTddASFJXxg&amp;course_id=Prw1rO-JEemu5uddujZ5YA#exercise-1"
                                                                    class="css-nn640c">
                                                                    <span class="css-l5xv05">
                                                                        <span class="css-17poqz3" lang="en">
                                                                            <span>{{ exercise.title_text_resolved ||
                                                                                'No title available' }}</span>
                                                                        </span>
                                                                    </span>
                                                                </a>
                                                            </span>
                                                            <span class="css-xvv9cp">
                                                                <span class="css-17poqz3">
                                                                    <span>

                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div v-for="section in exercise.sections" :key="section.id">
                                                        <component :is="getComponentName(section._type)"
                                                            :section="section" :references="references"
                                                            @handleParentMethod="handleParentMethod" />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <div id="custom-selection"></div>
</template>

<script>
import axios from 'axios';
import ArticleSection from '../../components/study_room/ArticleSection.vue';
import AsideSection from '../../components/study_room/AsideSection.vue';
import DialogSection from '../../components/study_room/DialogSection.vue';
import MediaSection from '../../components/study_room/MediaSection.vue';
import QuestionSection from '../../components/study_room/QuestionSection.vue';
import TeacherNoteSection from '../../components/study_room/TeacherNoteSection.vue';
import TitleSection from '../../components/study_room/TitleSection.vue';
import VocabSection from '../../components/study_room/VocabSection.vue';
import Lesson_Header from '../../components/study_room/Lesson_Header.vue';

export default {
    props: ['socket', 'currentRoomId', 'userId'],

    components: {
        ArticleSection,
        AsideSection,
        DialogSection,
        MediaSection,
        QuestionSection,
        TeacherNoteSection,
        TitleSection,
        VocabSection,
        Lesson_Header
    },
    data() {
        return {
            lesson_id: this.$route.params.id || null,
            Lessons: {},
            references: {},
            storedRange: null,

        };
    },
    mounted() {
        // document.addEventListener("mouseup", this.handleMouseUp);
        // document.addEventListener("mousedown", this.handleMouseDown);
        // window.addEventListener("resize", this.updateSelection);
    },
    beforeUnmount() {
        // document.removeEventListener("mouseup", this.handleMouseUp);
        // document.removeEventListener("mousedown", this.handleMouseDown);
        // window.removeEventListener("resize", this.updateSelection);
    },
    created() {
        if (this.lesson_id) {
            this.fetchLesson();
        } else {
            console.error('No Lesson ID provided.');
        }
    },
    methods: {
        async fetchLesson() {
            try {
                const response = await axios.get(`lessons/${this.lesson_id}`);
                console.log(response.data.data);
                this.Lessons = response.data.data;
                this.references = response.data.references;
                this.resolveExerciseTitles();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        ,
        getComponentName(type) {
            switch (type) {
                case 'ArticleSection':
                    return 'ArticleSection';
                case 'AsideSection':
                    return 'AsideSection';
                case 'DialogSection':
                    return 'DialogSection';
                case 'MediaSection':
                    return 'MediaSection';
                case 'QuestionSection':
                    return 'QuestionSection';
                case 'TeacherNoteSection':
                    return 'TeacherNoteSection';
                case 'TitleSection':
                    return 'TitleSection';
                case 'VocabSection':
                    return 'VocabSection';
                default:
                    return null;
            }
        },
        resolveReference(ref) {
            return this.references[ref];
        },
        resolveExerciseTitles() {
            if (this.Lessons.exercises) {
                this.Lessons.exercises.forEach(exercise => {
                    if (exercise.title_text && exercise.title_text._ref) {
                        const titleData = this.resolveReference(exercise.title_text._ref);
                        if (titleData) {
                            exercise.title_text_resolved = titleData.text;
                        } else {
                            exercise.title_text_resolved = 'No title available';
                        }
                    } else {
                        exercise.title_text_resolved = 'No title available';
                    }
                });
            }
        },
        handleParentMethod(ref, callback) {
            // Perform some logic with ref and return the result via callback
            console.log(ref)
            let result = this.references[ref] || null;

            console.log("momo:", result);
            callback(result);
        },


    }
};
</script>



<style scoped>
.css-2n2sa {
    list-style-type: none;
    margin: 0px;
    padding-left: 0px;
}

.css-nz0spn {
    display: inline;
}

.css-bbmg1v {
    font-family: var(--kf-font-family-default);
    font-size: var(--kf-font-detail-size-m);
    font-style: var(--kf-font-style-default);
    font-weight: var(--kf-font-detail-weight-strong);
    letter-spacing: var(--kf-font-letter-spacing-default);
    line-height: var(--kf-font-detail-line-height);
    color: var(--kf-color-grey-600);
}

.css-3g0s26 {
    box-sizing: border-box;
    max-width: 890px;
    margin: 5px auto;
    padding: 0px 20px;
}

.css-6o01w {
    background-color: white;
    border-radius: 2px;
    box-shadow: var(--kf-shadow-s);
    padding-block: 50px;
    padding-inline: 40px;
}

.css-1m7h2m5:not(:empty) {
    margin-bottom: 5px;

}

.css-9tjeri {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-direction: row;
}

.css-uun4qp {
    order: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;

}

.css-uun4qp {
    order: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.css-r7q7qr {
    margin-bottom: 5px;
}

.css-13zjvhz {
    font-weight: 600;
    margin-top: -0.3em;
    line-height: 1.25;
    font-size: 32px;

}

.css-l5xv05 {
    position: relative;
}

.css-moa3jd {
    background-color: white;
    border-radius: 2px;
    box-shadow: var(--kf-shadow-s);
    padding-block: 70px;
    padding-inline: 40px;
}

.css-5tu2hh {
    color: var(--kf-color-grey-600);
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 5px;

}

.css-nn640c {
    text-decoration: none;
    color: inherit;
}

.css-14lp8wm {
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 20px;
}

.css-197ofbd {
    color: rgb(64, 64, 64);
    display: inherit;
    flex-direction: inherit;
    -webkit-box-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    align-items: inherit;
    -webkit-box-flex: inherit;
    flex-grow: inherit;
    font-size: 16px;
}
</style>
