<template>
  <div class="bg-white p-0">
    <!-- Spinner Start -->
    <div id="spinner" v-if="isLoading"
      class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
      <div class="spinner-border text-primary" style="width: 3rem; height: 3rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
    <!-- Spinner End -->

    <!-- Navbar Start -->
    <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top px-4 px-lg-5 py-lg-0">
      <a href="index.html" class="navbar-brand">
        <!-- <h1 class="m-0 text-primary"><i class="fa fa-book-reader me-3"></i>Leaqo</h1> -->
        <h1 class="m-0 text-primary"><img src="../assets/imgs/chrome_7IW7z4ImAz.png" alt="leaqo logo"
            style="width: 100%; max-width: 250px;">
        </h1>
      </a>
      <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="navbar-nav mx-auto">
          <a href="index.html" class="nav-item nav-link active">Home</a>
          <a href="#aboutUs" class="nav-item nav-link">About Us</a>
          <a href="#School_Classes" class="nav-item nav-link">Classes</a>
          <div class="nav-item dropdown">
            <a href="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
            <div class="dropdown-menu rounded-0 rounded-bottom border-0 shadow-sm m-0">
              <a href="#" class="dropdown-item">School Facilities</a>
              <a href="#" class="dropdown-item">Popular Teachers</a>
              <a href="#" class="dropdown-item">Become A Teachers</a>
              <a href="#" class="dropdown-item">Make Appointment</a>
              <a href="#" class="dropdown-item">Testimonial</a>
              <a href="#" class="dropdown-item">404 Error</a>
            </div>
          </div>
          <a href="#Contact_Us" class="nav-item nav-link">Contact Us</a>
        </div>
        <a href="/login" class="btn btn-primary rounded-pill px-3 d-none d-lg-block">Login<i
            class="fa fa-arrow-right ms-3"></i></a>
      </div>
    </nav>
    <!-- Navbar End -->

    <!-- Carousel Start -->
    <div class="container-fluid p-0 mb-5">
      <div class="owl-carousel header-carousel position-relative">
        <div class="owl-carousel-item position-relative" style="max-height: 800px;">
          <img class="img-fluid" src="../assets/imgs/PLACEHOLDER_image4.jpg" alt="">
          <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style="background: rgba(0, 0, 0, .2);">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-10 col-lg-8">
                  <h1 class="display-2 text-white animated slideInDown mb-4">Learn and Engage Anytime with Quality,
                    Online</h1>
                  <p class="fs-5 fw-medium text-white mb-4 pb-2">Experience online learning that meets your needs,
                    offering a range of topics beyond English.</p>
                  <a href="" class="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft">Learn
                    More</a>
                  <a href="" class="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight">Our
                    Classes</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="owl-carousel-item position-relative">
          <img class="img-fluid"
            src="https://qsoe.educ.queensu.ca/sites/qsoe/files/program-hero/Untitled%20%28Photo%20Postcard%29%20%28910%20%C3%97%20500%20px%29.png"
            alt="">
          <div class="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
            style="background: rgba(0, 0, 0, .2);">
            <div class="container">
              <div class="row justify-content-start">
                <div class="col-10 col-lg-8">
                  <h1 class="display-2 text-white animated slideInDown mb-4">Learn and Engage Anytime with Quality,
                    Online</h1>
                  <p class="fs-5 fw-medium text-white mb-4 pb-2">Experience online learning that meets your needs,
                    offering a range of topics beyond English.</p>
                  <a href="" class="btn btn-primary rounded-pill py-sm-3 px-sm-5 me-3 animated slideInLeft">Learn
                    More</a>
                  <a href="" class="btn btn-dark rounded-pill py-sm-3 px-sm-5 animated slideInRight">Our
                    Classes</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Carousel End -->

    <!-- Facilities Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
          <h1 class="mb-3">School Facilities</h1>
          <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
            eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
        </div>
        <div class="row g-4">
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="facility-item">
              <div class="facility-icon bg-primary">
                <span class="bg-primary"></span>
                <i class="fa fa-bus-alt fa-3x text-primary"></i>
                <span class="bg-primary"></span>
              </div>
              <div class="facility-text bg-primary">
                <h3 class="text-primary mb-3">School Bus</h3>
                <p class="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="facility-item">
              <div class="facility-icon bg-success">
                <span class="bg-success"></span>
                <i class="fa fa-futbol fa-3x text-success"></i>
                <span class="bg-success"></span>
              </div>
              <div class="facility-text bg-success">
                <h3 class="text-success mb-3">Playground</h3>
                <p class="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="facility-item">
              <div class="facility-icon bg-warning">
                <span class="bg-warning"></span>
                <i class="fa fa-home fa-3x text-warning"></i>
                <span class="bg-warning"></span>
              </div>
              <div class="facility-text bg-warning">
                <h3 class="text-warning mb-3">Healthy Canteen</h3>
                <p class="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
            <div class="facility-item">
              <div class="facility-icon bg-info">
                <span class="bg-info"></span>
                <i class="fa fa-chalkboard-teacher fa-3x text-info"></i>
                <span class="bg-info"></span>
              </div>
              <div class="facility-text bg-info">
                <h3 class="text-info mb-3">Positive Learning</h3>
                <p class="mb-0">Eirmod sed ipsum dolor sit rebum magna erat lorem kasd vero ipsum sit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Facilities End -->

    <!-- About Start -->
    <div class="container-xxl py-5" id="aboutUs">
      <div class="container">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <h1 class="mb-4">Learn More About Our Work And Our Cultural Activities</h1>
            <p>Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.
              Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet
            </p>
            <p class="mb-4">Stet no et lorem dolor et diam, amet duo ut dolore vero eos. No stet est diam
              rebum amet diam ipsum. Clita clita labore, dolor duo nonumy clita sit at, sed sit sanctus
              dolor eos, ipsum labore duo duo sit no sea diam. Et dolor et kasd ea. Eirmod diam at dolor
              est vero nonumy magna.</p>
            <div class="row g-4 align-items-center">
              <div class="col-sm-6">
                <a class="btn btn-primary rounded-pill py-3 px-5" href="">Read More</a>
              </div>
              <div class="col-sm-6">
                <div class="d-flex align-items-center">
                  <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                    style="width: 45px; height: 45px;">
                  <div class="ms-3">
                    <h6 class="text-primary mb-1">Jhon Doe</h6>
                    <small>CEO & Founder</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 about-img wow fadeInUp" data-wow-delay="0.5s">
            <div class="row">
              <div class="col-12 text-center">
                <img class="img-fluid w-75 rounded-circle bg-light p-3" src="../assets/imgs/about-1.jpg" alt="">
              </div>
              <div class="col-6 text-start" style="margin-top: -150px;">
                <img class="img-fluid w-100 rounded-circle bg-light p-3" src="../assets/imgs/about-2.jpg" alt="">
              </div>
              <div class="col-6 text-end" style="margin-top: -150px;">
                <img class="img-fluid w-100 rounded-circle bg-light p-3" src="../assets/imgs/about-3.jpg" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Call To Action Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="bg-light rounded">
          <div class="row g-0">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s" style="min-height: 400px;">
              <div class="position-relative h-100">
                <img class="position-absolute w-100 h-100 rounded" src="../assets/imgs/call-to-action.jpg"
                  style="object-fit: cover;">
              </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <div class="h-100 d-flex flex-column justify-content-center p-5">
                <h1 class="mb-4">Become A Teacher</h1>
                <p class="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam
                  amet diam et eos.
                  Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore
                  erat amet
                </p>
                <a class="btn btn-primary py-3 px-5" href="">Get Started Now<i class="fa fa-arrow-right ms-2"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Call To Action End -->

    <!-- Classes Start -->
    <div class="container-xxl py-5" id="School_Classes">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
          <h1 class="mb-3">School Classes</h1>
          <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
            eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
        </div>
        <div class="row g-4">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-1.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">Art & Drawing</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-2.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">Color Management</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-3.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">Athletic & Dance</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-4.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">Language & Speaking</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-5.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">Religion & History</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="classes-item">
              <div class="bg-light rounded-circle w-75 mx-auto p-3">
                <img class="img-fluid rounded-circle" src="../assets/imgs/classes-6.jpg" alt="">
              </div>
              <div class="bg-light rounded p-4 pt-5 mt-n5">
                <a class="d-block text-center h3 mt-3 mb-4" href="">General Knowledge</a>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="d-flex align-items-center">
                    <img class="rounded-circle flex-shrink-0" src="../assets/imgs/user.jpg" alt=""
                      style="width: 45px; height: 45px;">
                    <div class="ms-3">
                      <h6 class="text-primary mb-1">Jhon Doe</h6>
                      <small>Teacher</small>
                    </div>
                  </div>
                  <span class="bg-primary text-white rounded-pill py-2 px-3" href="">$99</span>
                </div>
                <div class="row g-1">
                  <div class="col-4">
                    <div class="border-top border-3 border-primary pt-2">
                      <h6 class="text-primary mb-1">Age:</h6>
                      <small>3-5 Years</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-success pt-2">
                      <h6 class="text-success mb-1">Time:</h6>
                      <small>9-10 AM</small>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="border-top border-3 border-warning pt-2">
                      <h6 class="text-warning mb-1">Capacity:</h6>
                      <small>30 Kids</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Classes End -->

    <!-- Appointment Start -->
    <div class="container-xxl py-5" id="Contact_Us">
      <div class="container">
        <div class="bg-light rounded">
          <div class="row g-0">
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div class="h-100 d-flex flex-column justify-content-center p-5">
                <h1 class="mb-4">Make Appointment</h1>
                <form>
                  <div class="row g-3">
                    <div class="col-sm-6">
                      <div class="form-floating">
                        <input type="text" class="form-control border-0" id="gname" placeholder="Gurdian Name">
                        <label for="gname">Gurdian Name</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-floating">
                        <input type="email" class="form-control border-0" id="gmail" placeholder="Gurdian Email">
                        <label for="gmail">Gurdian Email</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-floating">
                        <input type="text" class="form-control border-0" id="cname" placeholder="Child Name">
                        <label for="cname">Child Name</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-floating">
                        <input type="text" class="form-control border-0" id="cage" placeholder="Child Age">
                        <label for="cage">Child Age</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-floating">
                        <textarea class="form-control border-0" placeholder="Leave a message here" id="message"
                          style="height: 100px"></textarea>
                        <label for="message">Message</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <button class="btn btn-primary w-100 py-3" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s" style="min-height: 400px;">
              <div class="position-relative h-100">
                <img class="position-absolute w-100 h-100 rounded" src="../assets/imgs/appointment.jpg"
                  style="object-fit: cover;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Appointment End -->

    <!-- Team Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
          <h1 class="mb-3">Popular Teachers</h1>
          <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
            eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
        </div>
        <div class="row g-4">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="team-item position-relative">
              <img class="img-fluid rounded-circle w-75" src="../assets/imgs/team-1.jpg" alt="">
              <div class="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div class="d-flex align-items-center">
                  <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item position-relative">
              <img class="img-fluid rounded-circle w-75" src="../assets/imgs/team-2.jpg" alt="">
              <div class="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div class="d-flex align-items-center">
                  <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="team-item position-relative">
              <img class="img-fluid rounded-circle w-75" src="../assets/imgs/team-3.jpg" alt="">
              <div class="team-text">
                <h3>Full Name</h3>
                <p>Designation</p>
                <div class="d-flex align-items-center">
                  <a class="btn btn-square btn-primary mx-1" href=""><i class="fab fa-facebook-f"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-twitter"></i></a>
                  <a class="btn btn-square btn-primary  mx-1" href=""><i class="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->

    <!-- Testimonial Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style="max-width: 600px;">
          <h1 class="mb-3">Our Clients Say!</h1>
          <p>Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore lorem kasd vero ipsum sit
            eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
        </div>
        <div class="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
          <div class="testimonial-item bg-light rounded p-5">
            <p class="fs-5">Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet
              dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
            <div class="d-flex align-items-center bg-white me-n5" style="border-radius: 50px 0 0 50px;">
              <img class="img-fluid flex-shrink-0 rounded-circle" src="../assets/imgs/testimonial-1.jpg"
                style="width: 90px; height: 90px;">
              <div class="ps-3">
                <h3 class="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
            </div>
          </div>
          <div class="testimonial-item bg-light rounded p-5">
            <p class="fs-5">Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet
              dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
            <div class="d-flex align-items-center bg-white me-n5" style="border-radius: 50px 0 0 50px;">
              <img class="img-fluid flex-shrink-0 rounded-circle" src="../assets/imgs/testimonial-2.jpg"
                style="width: 90px; height: 90px;">
              <div class="ps-3">
                <h3 class="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
            </div>
          </div>
          <div class="testimonial-item bg-light rounded p-5">
            <p class="fs-5">Tempor stet labore dolor clita stet diam amet ipsum dolor duo ipsum rebum stet
              dolor amet diam stet. Est stet ea lorem amet est kasd kasd erat eos</p>
            <div class="d-flex align-items-center bg-white me-n5" style="border-radius: 50px 0 0 50px;">
              <img class="img-fluid flex-shrink-0 rounded-circle" src="../assets/imgs/testimonial-3.jpg"
                style="width: 90px; height: 90px;">
              <div class="ps-3">
                <h3 class="mb-1">Client Name</h3>
                <span>Profession</span>
              </div>
              <i class="fa fa-quote-right fa-3x text-primary ms-auto d-none d-sm-flex"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonial End -->

    <!-- Footer Start -->
    <div class="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
      <div class="container py-5">
        <div class="row g-5">
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Get In Touch</h3>
            <p class="mb-2"><i class="fa fa-map-marker-alt me-3"></i>123 Street, New York, USA</p>
            <p class="mb-2"><i class="fa fa-phone-alt me-3"></i>+012 345 67890</p>
            <p class="mb-2"><i class="fa fa-envelope me-3"></i>info@example.com</p>
            <div class="d-flex pt-2">
              <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
              <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
              <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-youtube"></i></a>
              <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Quick Links</h3>
            <a class="btn btn-link text-white-50" href="">About Us</a>
            <a class="btn btn-link text-white-50" href="">Contact Us</a>
            <a class="btn btn-link text-white-50" href="">Our Services</a>
            <a class="btn btn-link text-white-50" href="">Privacy Policy</a>
            <a class="btn btn-link text-white-50" href="">Terms & Condition</a>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Photo Gallery</h3>
            <div class="row g-2 pt-2">
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-1.jpg" alt="">
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-2.jpg" alt="">
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-3.jpg" alt="">
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-4.jpg" alt="">
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-5.jpg" alt="">
              </div>
              <div class="col-4">
                <img class="img-fluid rounded bg-light p-1" src="../assets/imgs/classes-6.jpg" alt="">
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <h3 class="text-white mb-4">Newsletter</h3>
            <p>Dolor amet sit justo amet elitr clita ipsum elitr est.</p>
            <div class="position-relative mx-auto" style="max-width: 400px;">
              <input class="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email">
              <button type="button" class="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy; <a class="border-bottom" href="#">Your Site Name</a>, All Right Reserved.

              <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
              Designed By <a class="border-bottom" href="https://htmlcodex.com">HTML Codex</a>
            </div>
            <div class="col-md-6 text-center text-md-end">
              <div class="footer-menu">
                <a href="">Home</a>
                <a href="">Cookies</a>
                <a href="">Help</a>
                <a href="">FQAs</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-primary btn-lg-square back-to-top"><i class="fas fa-arrow-up"></i></a>
  </div>
</template>

<script>
import $ from 'jquery';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import { useHead } from '@vueuse/head';

useHead({
  title: 'Leaqo - Learn and Engage Anytime with Quality, Online',
  meta: [
    {
      name: 'description',
      content: 'Learn English online with qualified tutors from all over the world on Leaqo. Book a lesson today!',
    },
    {
      name: 'keywords',
      content: 'english learning, online lessons, english tutoring, learn english',
    },
  ],
});

// /* global $ */
export default {
  name: 'HomeView',
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    window.onload = () => {
      this.isLoading = false;
    };
  },
  mounted() {
    // Ensure the DOM is fully loaded before initializing owlCarousel
    $(".header-carousel").owlCarousel({
      autoplay: true,
      smartSpeed: 1500,
      items: 1,
      dots: true,
      loop: true,
      nav: true,
      navText: [
        '<i class="bi bi-chevron-left"></i>',
        '<i class="bi bi-chevron-right"></i>'
      ]
    });
  }

}
</script>

<style scoped src="../assets/home.css"></style>
<style scoped src="../assets/bootstrap.min.css"></style>
