<template>
    <div class="css-3g0s26">
        <div class="css-6o01w">
            <div class="css-1xdhyk6">
                <div class="css-1m7h2m5">
                    <nav aria-label="Breadcrumbs" class="css-bbmg1v" data-mouseover-dictionary-disabled="">
                        <ol class="css-2n2sa">
                            <li class="css-nz0spn">
                                <a href="https://Leaqo.com/" class="css-1unrqcp">Leaqo</a>
                            </li>
                            <li class="css-nz0spn">
                                <RouterLink class="css-1unrqcp" :to="'/study-room/categorize-view/'">
                                    Materials
                                </RouterLink>
                                <!-- <a href="/study-room/MaterialView/" class="css-1unrqcp">Materials</a> -->
                            </li>
                            <li class="css-nz0spn">
                                <a href="/study-room/lesson-header/0225ae09-5d63-41c2-bd75-693985d07d78"
                                    class="css-1unrqcp">
                                    <span class="css-17poqz3" lang="en">
                                        <span>Daily News</span>
                                    </span>
                                </a>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="css-9tjeri">
                    <div class="css-1lr1zj9" v-if="image">
                        <div class="css-nyr3n3">
                            <button type="button" class="css-4f999p" data-testid="lesson-image"
                                aria-label="Expand image" style="outline: none;">
                                <div class="css-1rux0er" v-if="image.url">
                                    <img alt="A side shot of a peacock displaying its feathers." class="css-1d052sh"
                                        :src="image.url" sizes="(max-width: 600px) 600px,300px" style="width: 300px;"
                                        loading="lazy">
                                </div>
                            </button>
                        </div>
                    </div>
                    <div class="css-uun4qp">
                        <div v-if="title_text" class="css-r7q7qr">
                            <div class="css-13zjvhz">
                                <span class="css-l5xv05">
                                    <span class="css-17poqz3" lang="en">
                                        <span v-if="title_text.text">{{ title_text.text }}</span>
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div class="css-r7q7qr">

                        </div>
                        <div class="css-1a1m660" data-mouseover-dictionary-disabled="">
                            <span class="css-l5xv05">June 04, 2024</span>
                        </div>
                        <div class="css-1kuy7z7">
                            <div class="css-20okp5" data-mouseover-dictionary-disabled="">
                                <div class="css-1n3aiw4">
                                    <div class="css-l5xv05">
                                        <span class="css-1nf8kel">
                                            <div class="css-tw54mm">6</div>
                                            <div class="css-7zr6s4">Intermediate</div>
                                        </span>
                                    </div>
                                </div>
                                <div class="css-l5xv05">
                                    <div class="css-s4zcxm">
                                        <div class="css-m3n5op">
                                            <a href="/app/daily-news/category/Science%20%26%20Technology"
                                                class="css-9g85br">Science &amp;
                                                Technology</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="css-1v3bpa8">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title_text', 'image'],

}
</script>

<style scoped>
.css-3g0s26 {
    box-sizing: border-box;
    max-width: 890px;
    margin: 5px auto;
    padding: 0px 20px;
}

.css-6o01w {
    background-color: white;
    border-radius: 2px;
    box-shadow: var(--kf-shadow-s);
    padding-block: 50px;
    padding-inline: 40px;
}

.css-1m7h2m5:not(:empty) {
    margin-bottom: 5px;
}

.css-9tjeri {
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-direction: row;
}

.css-bbmg1v {
    font-family: var(--kf-font-family-default);
    font-size: 14px;
    font-style: var(--kf-font-style-default);
    font-weight: var(--kf-font-detail-weight-strong);
    letter-spacing: var(--kf-font-letter-spacing-default);
    line-height: var(--kf-font-detail-line-height);
    color: #6D6D6D;
}

.css-2n2sa {
    list-style-type: none;
    margin: 0px;
    padding-left: 0px;
}

.css-nz0spn {
    display: inline;
}

.css-nz0spn:not(:last-of-type)::after {
    content: "/";
    margin: 0 var(--kf-static-space-s);
}

.css-1unrqcp {
    color: inherit;
    text-decoration: none;
}

.css-1lr1zj9 {
    flex-shrink: 0;
    order: 2;
    margin-left: 20px;
}

.css-uun4qp {
    order: 1;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.css-nyr3n3 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 1;
}

.css-4f999p {
    display: block;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    text-align: center;
    appearance: none;
    position: relative;
}

.css-r7q7qr {
    margin-bottom: 5px;
}

.css-13zjvhz {
    font-weight: 600;
    margin-top: -0.3em;
    line-height: 1.25;
    font-size: 32px;
}

.css-1a1m660 {
    color: #6D6D6D;
    font-weight: 600;
    font-size: 16px;
}

.css-s4zcxm {
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-flow: wrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin: calc(-2px);
}

.css-9g85br {
    all: unset;
    user-select: revert;
    font-family: var(--kf-font-family-default);
    font-size: var(--kf-font-detail-size-s);
    font-style: var(--kf-font-style-default);
    font-weight: var(--kf-font-detail-weight-strong);
    letter-spacing: var(--kf-font-letter-spacing-default);
    line-height: var(--kf-font-detail-line-height);
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    color: var(--kf-color-grey-600);
    display: inline-flex;
    height: var(--kf-static-size-150);
    --color-tag-icon: var(--kf-color-grey-500);
    cursor: pointer;
    background: var(--kf-color-grey-75);
    border-radius: var(--kf-static-size-25);
    padding: 0 var(--kf-static-space-xs);
    white-space: nowrap;
}
</style>