<template>
  <div>
    <div class="container">
      <div v-for="card in cards" :key="card.id" class="card-wrapper">
        <RouterLink :to="getLink(card.id)">
          <!-- <a :href="'https://engoo.com/app/daily-news/' + card.id" class="card-link"> -->
          <article class="card">
            <div class="card-content">
              <div class="image-container">
                <div class="image-wrapper">
                  <div class="image-inner-wrapper">
                    <img :alt="card.name_text.text" class="image" :src="card.image.url" loading="lazy">
                  </div>
                </div>
              </div>
              <div class="title-container">
                <span class="empty-span"></span>
                <h2 class="title">
                  <span class="title-span">
                    <span class="empty-span" lang="en">
                      <span>{{ card.name_text.text }}</span>
                    </span>
                  </span>
                </h2>
              </div>
              <div class="description-container">
                <span class="empty-span" lang="en">
                  <span>
                    <span>{{ card.short_description_text.text.split('\n')[0] }}</span><br>
                    <span>{{ card.short_description_text.text.split('\n')[1] }}</span>
                  </span>
                </span>
              </div>
            </div>
            <div class="level-container">
              <levelPart v-if="card && card.target_level_range" :min="card.target_level_range.min"
                :max="card.target_level_range.max" type="multi" />
            </div>
          </article>
          <!-- </a> -->
        </RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import levelPart from '../../components/study_room/small/levelPart.vue';

export default {


  // props: ['socket', 'currentRoomId', 'userId'],
  data() {
    return {
      cards: []
    };
  },
  components: {
    levelPart
  },
  created() {
    this.fetchCards();
  },
  computed: {
    shouldShowLessonHeader() {
      return this.$route.path.includes('study-room');
    }
  },
  methods: {
    getLink(id) {
      return this.shouldShowLessonHeader ? `/study-room/lesson-header/${id}` : `/lesson-header/${id}`;
    }
    ,
    fetchCards() {
      axios.get('categories')
        .then(response => {
          this.cards = response.data;
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    // navigate(route) {
    //   // this.$router.push(route);
    //   this.socket.send(JSON.stringify({
    //     type: 'navigate', route,
    //     roomId: this.currentRoomId,
    //     userId: this.userId
    //   }));
    // }
  }

}
</script>

<style scoped>
.container {
  -webkit-box-align: stretch;
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}

.card-wrapper {
  margin: 5px;
  width: calc(33% - 10px);
}

.card-link {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;
}

.card {
  background-color: white;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.10);
  border-radius: var(--kf-static-size-10);
  padding: var(--kf-static-space-0);
  transition: box-shadow 250ms ease-out 0s;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  font-size: 16px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  letter-spacing: 0.8px;
}

.card-content {
  padding: 25px 15px 10px;
}



.image-container {
  margin: 0px auto;
  max-width: 200px;
  text-align: center;
  filter: brightness(100%) contrast(100%);
  transition: filter 0.15s linear 0s;
  width: 60%;
}

.title-container {
  margin: 10px;
  text-align: center;
}

.description-container {
  color: var(--kf-color-grey-600);
  font-size: 16px;
  letter-spacing: 0.8px;
  text-align: center;
}

.image-wrapper {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}

.image-inner-wrapper {
  position: absolute;
  inset: 0px;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.level {
  display: inline-block;
  font-size: 12px;
  vertical-align: middle;
}

.separator {
  color: var(--kf-color-grey-900);
  font-size: 12px;
  margin: 0px 10px;
  vertical-align: middle;
}

.level-detail {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  color: var(--kf-color-grey-900);
}


.empty-span {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  background: white;
  font-family: "Open Sans", "Eikaiwa Icons", sans-serif;
}

.title {
  color: var(--kf-color-grey-900);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.8px;
  margin: 0px;
}

.level-container {
  border-top: 2px solid var(--kf-color-grey-75);
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 10px 0px;
}
</style>
