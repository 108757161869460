<template>
    <div style="flex:2">
        <!-- Room Selection Section -->
        <div v-if="false" id="room-selection-container" class="centered">
            <h1>Plataforma de videoconferencia WebRTC para múltiples usuarios</h1>
            <label>Introduzca el número de la sala</label>
            <input v-model="roomId" type="text" />
        </div>
        <button @click="joinRoom">CONNECT</button>
        <!-- <button @click="shareScreen">Share Screen</button> -->
        <!-- <button @click="toggleCamera">{{ isCameraOn ? 'Turn Off Camera' : 'Turn On Camera' }}</button> -->
        <!-- <button @click="toggleMic">{{ isMicOn ? 'Mute Mic' : 'Unmute Mic' }}</button> -->
        <!-- <p>Camera status: {{ isCameraOn }}</p> -->




        <!-- Video Chat Section -->
        <div v-if="true" id="video-chat-container" class="video-position vid_container block_force">
            <video ref="localVideo" autoplay muted>

            </video>
            <div class="css-mtnebu">
                <div class="css-py5jdu">
                    <div class="css-28kyjh">
                        <button type="button" @click="toggleMic" aria-label="Turn microphone on"
                            title="Turn microphone on" class="css-1cqo9ej" aria-disabled="false" style="outline: none;">
                            <span class="css-xxdqwu">
                                <span class="css-q4g7c2">
                                    <svg fill="none" viewBox="0 0 1590 1590" width="1em" height="1em"
                                        class="css-18an3v2" aria-hidden="true" style="width: 100%;">
                                        <g fill="none" fill-rule="evenodd">
                                            <g transform="translate(508 386)" fill="currentColor">
                                                <rect x="129" width="311" height="571" rx="155.5" stroke="currentColor">
                                                </rect>
                                                <path
                                                    d="M28 310h22.3c15.46 0 28 12.54 28 28v87.04a28 28 0 00.6 5.76c9.89 47.08 23 81.4 39.35 102.96 29.14 38.45 90.18 89.23 165.92 85.26C491.15 608.17 488.5 419.3 489.45 411.13c.94-8.17 66.7-40 77.13.13 7.35 28.3-14.27 128.4-54.47 175.2-40.19 46.8-84.17 84.63-161.12 104.08-76.96 19.46-147.35-5.3-147.83-5.3-2.43 0-82.45-24.22-143.24-99.92C29.82 547.85 9.95 496.06.29 429.96A28 28 0 010 425.9V338c0-15.46 12.54-28 28-28z">
                                                </path>
                                                <rect x="129" y="750" width="311" height="77" rx="28">

                                                </rect>
                                                <path
                                                    d="M517.x17 310H540c15.46 0 28 12.54 28 28v65.06a28 28 0 01-24.35 27.76l-22.95 3.02c-15.33 2.01-29.4-8.79-31.4-24.12a28 28 0 01-.25-3.7l.12-68.07c.02-15.44 12.55-27.95 28-27.95zM245 689h79v112h-79z">
                                                </path>
                                            </g>
                                            <rect v-if="!isMicOn" fill="var(--kf-color-grey-900)"
                                                transform="rotate(45 761.5 751.5)" x="722" y="238" width="79"
                                                height="1027" rx="39.5">
                                            </rect>
                                            <rect v-if="!isMicOn" fill="currentColor" transform="rotate(45 792.5 781.5)"
                                                x="753" y="268" width="79" height="1027" rx="39.5">
                                            </rect>
                                        </g>
                                    </svg>
                                    <!-- <svg width="100%" height="100%" viewBox="0 0 1590 1590"
                                        xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet"
                                        class="css-po8edp">
                                        <g fill="none" fill-rule="evenodd">
                                            <circle fill="black" cx="795" cy="795" r="795">

                                            </circle>
                                            <path
                                                d="M723 1054.47c3.46-41.92 40.03-73.26 82-70.27 41.97-2.99 78.54 28.35 82 70.27-3.43 41.95-40 73.32-82 70.33-41.98 2.96-78.54-28.4-82-70.33zM742.76 920L725 469h153l-11.5 451H742.76z"
                                                fill="var(--kf-color-white)">
                                            </path>
                                        </g>
                                    </svg> -->
                                </span>
                            </span>
                        </button>
                        <button type="button" @click="toggleCamera" aria-label="Turn camera on" title="Turn camera on"
                            class="css-1cqo9ej" aria-disabled="false" style="outline: none;">
                            <span class="css-xxdqwu">
                                <span class="css-q4g7c2">
                                    <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" class="css-18an3v2"
                                        aria-hidden="true">
                                        <path fill="currentColor" fill-rule="evenodd"
                                            d="M15.19 5.5a2.1 2.1 0 0 0-1.36-.5H4.09C2.94 5 2 5.93 2 7.09v9.76c0 .51.19.98.5 1.35L15.18 5.5ZM4.56 18.94 15.91 7.59v9.26c0 1.15-.93 2.09-2.08 2.09H4.56Zm15.8-12.08.88-.58.31-.11a1.17 1.17 0 0 1 1.33 1.16v9.32a1.13 1.13 0 0 1-1.25 1.12 1.66 1.66 0 0 1-.55-.17l-4-2.73V9.13l3.26-2.26.02-.01Z"
                                            clip-rule="evenodd">
                                        </path>
                                        <path v-if="isCameraOn" fill="currentColor"
                                            d="M21.26 3.77a1 1 0 0 0-1.4-1.4L2.99 19.23a1 1 0 1 0 1.4 1.4L21.27 3.78Z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
                        </button>
                        <button type="button" @click="shareScreen" aria-label="Share screen" title="Share screen"
                            class="css-1cqo9ej" aria-disabled="false" style="outline: none;">
                            <span class="css-xxdqwu">
                                <span class="css-q4g7c2">
                                    <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em" class="css-18an3v2"
                                        aria-hidden="true">
                                        <path fill="currentColor"
                                            d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2zm16 2H4v12h16V6zm-5 6l-4 4v-3H7v-2h4V8l4 4z">
                                        </path>
                                    </svg>
                                </span>
                            </span>
                        </button>


                    </div>
                </div>
                <span class="css-i9gxme">

                </span>
            </div>

        </div>

    </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
    props: ['socket', 'userId'], // Using WebSocket instead of Socket.io
    setup(props) {
        const roomId = ref(""); // Room ID entered by the user
        const showRoomSelection = ref(true); // Whether to show the room selection
        const localVideo = ref(null); // Reference to the local video element

        const peerConnections = {}; // Stores peer connections
        let localStream;
        const isCameraOn = ref(true); // Track the state of the camera (on/off)
        const isMicOn = ref(true); // Track the state of the microphone (on/off)

        // const isScreenSharing = ref(false); // Track screen sharing state


        // const iceServers = {
        //     iceServers: [
        //         { urls: "stun:stun.l.google.com:19302" },
        //         { urls: "stun:stun1.l.google.com:19302" },
        //     ],
        // };
        const iceServers = {
            iceServers: [
                { urls: ["stun:ss-turn1.xirsys.com"] },
                {
                    username: "rgJIWgaZSSS99poytIubCuXnPONH4w2s7EIv8c1zjiBGJQlwtaKtpFX_c3O8l0aaAAAAAGZC67ZzZGZ3YWU=",
                    credential: "5f73b0e6-11ac-11ef-9b5d-0242ac140004",
                    urls: [
                        "turn:ss-turn1.xirsys.com:80?transport=udp",
                        "turn:ss-turn1.xirsys.com:3478?transport=udp",
                        "turn:ss-turn1.xirsys.com:80?transport=tcp",
                        "turn:ss-turn1.xirsys.com:3478?transport=tcp",
                        "turns:ss-turn1.xirsys.com:443?transport=tcp",
                        "turns:ss-turn1.xirsys.com:5349?transport=tcp"
                    ]
                }
            ]

        };

        // Function to join a room
        const joinRoom = () => {
            roomId.value = 1;
            if (roomId.value === "") {
                alert("Please type a room ID");
                return;
            }

            props.socket.send(JSON.stringify({ type: "join", room: roomId.value, peerId: props.userId }));
            showRoomSelection.value = false;
            setLocalStream();
        };

        // Function to set the local video stream
        const setLocalStream = async () => {
            try {
                localStream = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: true,
                });
                localVideo.value.srcObject = localStream;
                localVideo.value.id = 'local_video';  // Assign your desired ID here
                isCameraOn.value = false;
                isMicOn.value = true;

                // localVideo.value.classList.add('small-video');
            } catch (error) {
                console.error("Could not get user media", error);
            }
        };
        // Screen sharing function
        const shareScreen = async () => {
            try {
                // Capture the screen
                const screenStream = await navigator.mediaDevices.getDisplayMedia({
                    video: true
                });

                // Get the screen track
                const screenTrack = screenStream.getVideoTracks()[0];

                // Replace the existing video track with the screen track for all peer connections
                Object.values(peerConnections).forEach((peerConnection) => {
                    const sender = peerConnection.getSenders().find((s) => s.track.kind === "video");
                    if (sender) {
                        sender.replaceTrack(screenTrack);
                    }
                });

                // Update local video element with the screen sharing stream
                localVideo.value.srcObject = screenStream;

                // Handle the end of screen sharing
                screenTrack.onended = async () => {
                    // Revert back to the original camera stream when screen sharing ends
                    localStream = await navigator.mediaDevices.getUserMedia({
                        video: true,
                        audio: true,
                    });
                    const cameraTrack = localStream.getVideoTracks()[0];

                    // Replace the screen track with the original camera track for all peer connections
                    Object.values(peerConnections).forEach((peerConnection) => {
                        const sender = peerConnection.getSenders().find((s) => s.track.kind === "video");
                        if (sender) {
                            sender.replaceTrack(cameraTrack);
                        }
                    });

                    // Update local video element with the camera stream
                    localVideo.value.srcObject = localStream;
                };
            } catch (error) {
                console.error("Error sharing screen:", error);
            }
        };


        // Function to handle the creation of an offer
        const createOffer = async (peerConnection, remotePeerId) => {
            let sessionDescription;
            try {
                sessionDescription = await peerConnection.createOffer({
                    offerToReceiveVideo: 1,
                    offerToReceiveAudio: 1,
                });
                peerConnection.setLocalDescription(sessionDescription);
            } catch (error) {
                console.error(error);
            }

            props.socket.send(
                JSON.stringify({
                    type: "webrtc_offer",
                    sdp: sessionDescription,
                    roomId: roomId.value,
                    senderId: props.userId,
                    receiverId: remotePeerId,
                })
            );
        };

        // Function to handle the creation of an answer
        const createAnswer = async (peerConnection, remotePeerId) => {
            let sessionDescription;
            try {
                sessionDescription = await peerConnection.createAnswer();
                peerConnection.setLocalDescription(sessionDescription);
            } catch (error) {
                console.error(error);
            }

            props.socket.send(
                JSON.stringify({
                    type: "webrtc_answer",
                    sdp: sessionDescription,
                    roomId: roomId.value,
                    senderId: props.userId,
                    receiverId: remotePeerId,
                })
            );
        };

        // Function to handle ICE candidate exchange
        const sendIceCandidate = (event, remotePeerId) => {
            if (event.candidate) {
                const sdpMLineIndex = event.candidate.sdpMLineIndex !== null ? event.candidate.sdpMLineIndex : 0;
                const sdpMid = event.candidate.sdpMid !== null ? event.candidate.sdpMid : '0';

                props.socket.send(
                    JSON.stringify({
                        type: "webrtc_ice_candidate",
                        senderId: props.userId,
                        receiverId: remotePeerId,
                        roomId: roomId.value,
                        label: sdpMLineIndex,
                        candidate: event.candidate.candidate,
                        sdpMid: sdpMid, // Default to '0' if missing
                    })
                );
            } else {
                console.log("ICE Candidate is null.");
            }
        };




        // Handle WebSocket events
        onMounted(() => {
            props.socket.addEventListener("message", async (event) => {
                let message;

                if (event.data instanceof Blob) {
                    const text = await event.data.text(); // Convert Blob to text
                    message = JSON.parse(text); // Now parse the text as JSON
                } else {
                    message = JSON.parse(event.data); // Directly parse if it's not a Blob
                }


                if (message.type === "room_created") {
                    console.log(`Room created: ${message.roomId}`);
                    await setLocalStream();
                }

                if (message.type === "room_joined") {
                    console.log(`Joined room: ${message.roomId}`);
                    await setLocalStream();
                    props.socket.send(
                        JSON.stringify({ type: "start_call", roomId: message.roomId, senderId: props.userId })
                    );
                }

                if (message.type === "start_call") {
                    const remotePeerId = message.senderId;
                    initializePeerConnection(remotePeerId);
                    await createOffer(peerConnections[remotePeerId], remotePeerId);
                }

                if (message.type === "webrtc_offer") {
                    console.log("creating");
                    const remotePeerId = message.senderId;
                    initializePeerConnection(remotePeerId);

                    peerConnections[remotePeerId].setRemoteDescription(
                        new RTCSessionDescription(message.sdp)
                    );

                    await createAnswer(peerConnections[remotePeerId], remotePeerId);
                }

                if (message.type === "webrtc_answer") {
                    peerConnections[message.senderId].setRemoteDescription(
                        new RTCSessionDescription(message.sdp)
                    );
                }

                if (message.type === "webrtc_ice_candidate") {
                    console.log("Received ICE Candidate:", message);

                    // Check if sdpMid and sdpMLineIndex are not null
                    if (message.candidate && message.sdpMLineIndex !== null && message.sdpMid !== null) {
                        try {
                            const candidate = new RTCIceCandidate({
                                sdpMLineIndex: message.sdpMLineIndex,
                                candidate: message.candidate,
                                sdpMid: message.sdpMid,
                            });
                            await peerConnections[message.senderId].addIceCandidate(candidate);
                        } catch (e) {
                            console.error("Error adding received ICE candidate", e);
                        }
                    } else {
                        console.warn("Received ICE candidate with missing sdpMid or sdpMLineIndex, skipping:", message);
                    }
                }

            });
        });
        function initializePeerConnection(remotePeerId) {
            peerConnections[remotePeerId] = new RTCPeerConnection(iceServers);

            addLocalTracks(peerConnections[remotePeerId]);

            peerConnections[remotePeerId].ontrack = (event) => {
                setRemoteStream(event, remotePeerId);
            };

            peerConnections[remotePeerId].onicecandidate = (event) =>
                sendIceCandidate(event, remotePeerId);

            peerConnections[remotePeerId].oniceconnectionstatechange = (event) =>
                checkPeerDisconnect(event, remotePeerId);
        }

        const addLocalTracks = (peerConnection) => {
            localStream.getTracks().forEach((track) => {
                peerConnection.addTrack(track, localStream);
            });

        };

        const setRemoteStream = (event, remotePeerId) => {
            const existingVideo = document.getElementById(`remotevideo_${remotePeerId}`);
            console.log(Object.keys(peerConnections).length);

            // Check if the video element for this peer already exists
            if (!existingVideo) {
                const remoteVideo = document.createElement("video");
                remoteVideo.srcObject = event.streams[0];
                remoteVideo.autoplay = true;
                remoteVideo.id = `remotevideo_${remotePeerId}`;
                remoteVideo.classList.add("remote-video"); // Add a class for styling
                document.getElementById("video-chat-container").appendChild(remoteVideo);
            }

            // Remove 'small-video' class from local_video if peerConnections length is 2 or more


            if (Object.keys(peerConnections).length >= 2) {
                const video_container = document.getElementById("video-chat-container");
                const localVideo = document.getElementById("local_video");
                if (localVideo && localVideo.classList.contains("small-video")) {
                    localVideo.classList.remove("small-video");
                }
                if (video_container && video_container.classList.contains("block_force")) {
                    video_container.classList.remove("block_force");
                }

            } else {
                // Add 'small-video' class back if peerConnections length is less than 2
                const video_container = document.getElementById("video-chat-container");
                const localVideo = document.getElementById("local_video");
                if (localVideo && !localVideo.classList.contains("small-video")) {
                    localVideo.classList.add("small-video");
                }
                if (video_container && !video_container.classList.contains("block_force")) {
                    video_container.classList.add("block_force");
                }
            }
        };


        function checkPeerDisconnect(event, remotePeerId) {
            const state = peerConnections[remotePeerId].iceConnectionState;
            console.log(`Connection with peer ${remotePeerId}: ${state}`);
            if (state === "failed" || state === "closed" || state === "disconnected") {
                console.log(`Peer ${remotePeerId} has disconnected`);
                const videoDisconnected = document.getElementById(
                    "remotevideo_" + remotePeerId
                );
                if (videoDisconnected) {
                    videoDisconnected.remove();
                }
                delete peerConnections[remotePeerId];

                console.log("Object.keys(peerConnections).length:", Object.keys(peerConnections).length)

                if (Object.keys(peerConnections).length == 0) {
                    const video_container = document.getElementById("video-chat-container");
                    const localVideo = document.getElementById("local_video");
                    if (localVideo && localVideo.classList.contains("small-video")) {
                        localVideo.classList.remove("small-video");
                    }
                    if (video_container && !video_container.classList.contains("block_force")) {
                        video_container.classList.add("block_force");
                    }

                } else if (Object.keys(peerConnections).length >= 2) {
                    const video_container = document.getElementById("video-chat-container");
                    const localVideo = document.getElementById("local_video");
                    if (localVideo && localVideo.classList.contains("small-video")) {
                        localVideo.classList.remove("small-video");
                    }
                    if (video_container && video_container.classList.contains("block_force")) {
                        video_container.classList.remove("block_force");
                    }

                } else {
                    // Add 'small-video' class back if peerConnections length is less than 2
                    const video_container = document.getElementById("video-chat-container");
                    const localVideo = document.getElementById("local_video");
                    if (localVideo && !localVideo.classList.contains("small-video")) {
                        localVideo.classList.add("small-video");
                    }
                    if (video_container && !video_container.classList.contains("block_force")) {
                        video_container.classList.add("block_force");
                    }
                }
            }
        }
        const toggleCamera = () => {
            // Check if localStream is initialized and has video tracks
            if (localStream && localStream.getVideoTracks().length > 0) {
                const videoTrack = localStream.getVideoTracks()[0];

                // Log the current status of the video track
                console.log('Current camera status (enabled):', videoTrack.enabled);

                // Toggle the video track's enabled state
                videoTrack.enabled = !videoTrack.enabled;

                // Update the camera status and log the new status
                isCameraOn.value = videoTrack.enabled;
                console.log('Camera status updated to:', isCameraOn.value);
            } else {
                console.error('localStream is not initialized or no video tracks found');
            }
        };


        const toggleMic = () => {
            if (localStream && localStream.getAudioTracks().length > 0) {
                const audioTrack = localStream.getAudioTracks()[0];
                audioTrack.enabled = !audioTrack.enabled; // Toggle the audio track's enabled state
                isMicOn.value = audioTrack.enabled;      // Update the microphone status
            }
        };

        return {
            roomId,
            showRoomSelection,
            joinRoom,
            localVideo,
            shareScreen,
            toggleCamera,
            toggleMic, isCameraOn,
            isMicOn
        };
    },
};
</script>


<style>
.centered {
    text-align: center;
    margin-top: 100px;
}

.video-position {
    text-align: center;
}

#video-chat-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    justify-content: center;
    padding: 20px;
}

#video-chat-container video {
    width: 100%;
    height: auto;
    border-radius: 8px;
    border: 2px solid #ddd;
}

@media (min-width: 768px) {
    #video-chat-container {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}

@media (min-width: 1024px) {
    #video-chat-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

.centered {
    text-align: center;
    margin-top: 50px;
}

.video-position {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.vid_container {
    position: relative;
    background: #252525;
    /* display: block !important; */
}

.small-video {
    position: absolute;
    width: 200px !important;
    bottom: 0;
    right: 0;
}

.remote-video {
    max-height: 595px;
}

.block_force {
    display: block !important;
}



/* video tools */
.css-mtnebu {
    z-index: 2;
    position: absolute;
    box-sizing: border-box;
    padding: 15px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    pointer-events: auto;
    top: 10px;
}

.css-py5jdu {
    display: flex;
    height: 100%;
    flex-direction: column;
}

.css-28kyjh {
    display: flex;
    flex-direction: column;
    height: 126px;
    max-height: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
}

.css-1cqo9ej {
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    text-align: center;
    appearance: none;
    position: relative;
    cursor: pointer;
    opacity: 1;
    display: block;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    transition: transform 0.2s ease-in-out, opacity 0.8s;
}

.css-xxdqwu {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
}

.css-q4g7c2 {
    position: relative;
    display: flex;
    border-radius: 100%;
    box-shadow: rgb(184, 184, 184) 0px 0px 2px 0px;
    background-color: black;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 36px;
    height: 36px;
}

.css-18an3v2 {
    display: inline-block;
    width: 60%;
    height: auto;
    color: var(--kf-color-white);
}
</style>