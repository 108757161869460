<!-- TitleSection.vue -->
<template>
    <div>
        <!-- Your template for TitleSection -->
        <!-- <p>Dialog Section with ID: {{ section.id }}</p> -->
        <div>
            <div class="css-lvt8z7">
                <div class="css-b2mok6">
                    <div>
                        <div>
                            <div class="css-1rj1g7w">
                                <div class="css-1o4bhdp">
                                    <table class="css-cibdzb">
                                        <tbody>
                                            <tr v-for="dialog_entrie in section.dialog_entries" :key="dialog_entrie.id">
                                                <td class="css-e65kwr">
                                                    <div class="css-nyr3n3">
                                                        <button type="button" class="css-4f999p"
                                                            data-testid="lesson-image" aria-label="Expand image"
                                                            style="outline: none;">
                                                            <div class="css-1rux0er">
                                                                <img alt="" class="css-1fjm13x"
                                                                    v-if="get_dialog_speaker_data(dialog_entrie).image"
                                                                    :src="get_dialog_speaker_data(dialog_entrie).image.url"
                                                                    loading="lazy">
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div class="css-1afpnq6" data-mouseover-dictionary-disabled="">
                                                        <div class="css-l5xv05">
                                                            {{ get_dialog_speaker_data(dialog_entrie).name }}</div>
                                                    </div>
                                                </td>
                                                <td class="css-yqmmdn">
                                                    <div class="css-18sxk07">
                                                        <span>
                                                            <span class="css-l5xv05"
                                                                v-for="dialog_sentence in dialog_entrie.dialog_sentences"
                                                                :key="dialog_sentence.id">
                                                                <span class="css-17poqz3" lang="en">
                                                                    <span>{{ dialog_sentence.text.text
                                                                        }}</span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div class="css-yzmnre">
                                                            <div class="css-t0r8k9">

                                                            </div>
                                                            <span class="css-17poqz3">
                                                                <span>

                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!-- <tr>
                                                <td class="css-e65kwr">
                                                    <div class="css-nyr3n3">
                                                        <button type="button" class="css-4f999p"
                                                            data-testid="lesson-image" aria-label="Expand image"
                                                            style="outline: none;">
                                                            <div class="css-1rux0er">
                                                                <img alt="" class="css-1fjm13x"
                                                                    src="https://transcode-v2.app.engoo.com/image/fetch/f_auto,c_limit,w_100,h_100,dpr_2/https://assets.app.engoo.com/images/0rpMxa7QlJr8HIqqCYC1eb.png"
                                                                    loading="lazy">
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div class="css-1afpnq6" data-mouseover-dictionary-disabled="">
                                                        <div class="css-l5xv05">Ling</div>
                                                    </div>
                                                </td>
                                                <td class="css-yqmmdn">
                                                    <div class="css-18sxk07">
                                                        <span>
                                                            <span class="css-l5xv05">
                                                                <span class="css-17poqz3" lang="en">
                                                                    <span>
                                                                        <span class="css-hl70jh"
                                                                            data-quiz-blank="true">(1)</span>
                                                                        <span>.</span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                        <div class="css-yzmnre">
                                                            <div class="css-t0r8k9">

                                                            </div>
                                                            <span class="css-17poqz3">
                                                                <span>

                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr> -->
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <div class="css-18xwsgn">
                                        <div style="display: flex; align-items: baseline;">
                                            <div class="css-1yzarxl">
                                                <span>1.</span>
                                            </div>
                                            <div class="css-kbrqek">
                                                <div class="css-19rokqe">
                                                    <div>
                                                        <span>Choose an answer from the options below that fits in
                                                            blank 1</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="css-5fo5ce">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section', 'references'],
    computed: {
        dialog_speaker() {
            return (dialog_entrie) => {
                const dialog_speakerRef = dialog_entrie.dialog_speaker ? dialog_entrie.dialog_speaker._ref : null;
                if (dialog_speakerRef && this.references[dialog_speakerRef]) {
                    const dialog_speaker_data = this.references[dialog_speakerRef];
                    return {
                        name: dialog_speaker_data.name,
                        image: dialog_speaker_data.image,
                        // part_of_speech: dialog_speaker_data.part_of_speech,
                        // definition: dialog_speaker_data.definition,
                        // pronunciations: dialog_speaker_data.pronunciations[0]
                    };
                }
                return {};
            };
        },
    }, methods: {
        get_dialog_speaker_data(dialog_entrie) {
            return this.dialog_speaker(dialog_entrie);
        }
    }
};
</script>

<style scoped>
.css-lvt8z7 {
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-flow: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin: calc(-37.5px) 0px;
}

.css-b2mok6 {
    margin: calc(37.5px) 0px;
}

.css-1rj1g7w {
    background-color: white;
    padding: 4px;
    margin: 0px -4px;
    border-radius: 3px;
}

.css-1o4bhdp {
    margin: -7.5px 0px;
}

.css-cibdzb {
    width: 100%;
    border-spacing: 0px 15px;
}

.css-e65kwr {
    white-space: nowrap;
    vertical-align: top;
    padding: 0px 5px;
}

.css-yqmmdn {
    line-height: 24px;
    padding: 0px;
    width: 100%;
    vertical-align: top;
}

.css-nyr3n3 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 1;
}

.css-4f999p {
    display: block;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    text-align: center;
    appearance: none;
    position: relative;
}

.css-1rux0er {
    position: relative;
    display: inherit;
    flex: inherit;
    -webkit-box-pack: inherit;
    justify-content: inherit;
    -webkit-box-align: inherit;
    align-items: inherit;
    flex-direction: inherit;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;
}

.css-1fjm13x {
    height: auto;
    width: auto;
    max-width: 64px;
    max-height: 64px;
    aspect-ratio: 1025 / 1025;
    border-radius: 3px;
    display: block;
}

.css-1afpnq6 {
    font-size: 13px;
    color: var(--kf-color-grey-900);
    line-height: 24px;
    text-align: center;
}

.css-18sxk07 {
    position: relative;
    padding: 15px;
    background-color: rgb(242, 242, 242);
    line-height: 1.5;
    border-radius: 4px;
    margin-left: 20px;
}

.css-18sxk07::before {
    position: absolute;
    content: "";
    top: 18px;
    background-image: url(data:image/svg+xml;charset=utf-8;base64,CiAgPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIxNXB4IiB2aWV3Qm94PSIwIDAgMTAgMTAiCiAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPgogICAgPHBhdGgKICAgICAgZmlsbD0iI2YyZjJmMiIKICAgICAgZD0iTTEwIDAgTDAgNSBMIDEwIDEwIFoiCiAgICAvPgogIDwvc3ZnPgo=);
    left: -20px;
    width: 20px;
    height: 15px;
}

.css-hl70jh {
    display: inline-block;
    border-bottom: 1px solid black;
    min-width: 80px;
    padding: 0px 1em;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.5;
    position: relative;
    top: -3px;
}
</style>