<template>
  <!-- <RouterLink to="/">Go to Home</RouterLink>
  <RouterLink to="/categorize-view">Materials</RouterLink>
  <RouterLink to="/study-room">Go to study Room </RouterLink>
  <RouterLink to="/lesson-view">Go to lesson</RouterLink> -->
  <div id="app">
    <router-view />
  </div>
</template>

<script>


export default {
  name: 'App'
};
</script>

<style>
/* Global styles here */
</style>