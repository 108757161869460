<template>
    <div class="sidebar">
        <ul>
            <li><router-link to="/dashboard">Dashboard</router-link></li>
            <li><router-link to="/categorize-view">My Material</router-link></li>
            <li><router-link to="/study-room/categorize-view">Study Room</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'DashboardSidebar'
}
</script>

<style scoped>
.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #2c3e50;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
}

ul {
    list-style: none;
    padding: 0;
}

li {
    padding: 15px;
}

a {
    color: white;
    text-decoration: none;
}
</style>