import { createRouter, createWebHistory } from "vue-router";
// import { createRouter } from "vue-router";
// import Vue from "vue";
import HomeView from "../views/HomeView.vue";
import StudyRoom from "@/views/StudyRoom.vue";
// import MaterialView from "@/views/MaterialView.vue";
import CategorizeView from "@/views/materials/CategorizeView.vue";
import LessonHeader from "@/views/materials/LessonHeader.vue";
import LessonView from "@/views/materials/LessonView.vue";
import loginView from "@/views/LoginView.vue";
import dashboardView from "@/views/dashboardView.vue";
import { jwtDecode } from "jwt-decode";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/login",
    name: "loginView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: loginView,
  },
  {
    path: "/dashboard",
    name: "dashboardView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: dashboardView,
    meta: { requiresAuth: true },
  },

  {
    path: "/categorize-view",
    name: "CategorizeView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CategorizeView,
  },
  {
    path: "/lesson-header/:id",
    name: "LessonHeader",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: LessonHeader,
  },
  {
    path: "/lesson-view/:id",
    name: "LessonView",
    component: LessonView,
  },
  {
    path: "/study-room",
    name: "StudyRoom",
    component: StudyRoom,
    meta: { requiresAuth: true },

    children: [
      {
        path: "categorize-view",
        name: "MaterialView",
        component: CategorizeView,
      },
      {
        path: "lesson-header/:id",
        name: "LessonHeaderChild",
        component: LessonHeader,
      },
      {
        path: "lesson-view/:id",
        name: "LessonViewChild",
        component: LessonView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds

      if (decodedToken.exp && decodedToken.exp < currentTime) {
        localStorage.removeItem("token"); // Remove expired token
        next("/login"); // Redirect to login if the token is expired
      } else {
        next(); // Token is valid, proceed to the route
      }
    } catch (error) {
      localStorage.removeItem("token"); // Remove invalid token
      next("/login"); // Redirect to login if token is invalid
    }
  } else {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      next("/login"); // Redirect to login if trying to access a protected route without a token
    } else {
      next(); // Proceed to the route
    }
  }
});

export default router;
