<template>
    <div>
        <Sidebar />
        <div class="main-content">
            <Navbar />
            <router-view />
        </div>
    </div>
</template>

<script>
import Sidebar from '../components/dashboard/Sidebar.vue'
import Navbar from '../components/dashboard/Navbar.vue'

export default {
    components: {
        Sidebar,
        Navbar
    }
}
</script>

<style>
/* #app {
    display: flex;
} */

.main-content {
    flex: 1;
    margin-left: 250px;
    /* Adjust based on sidebar width */
}
</style>ء