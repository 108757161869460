<!-- TitleSection.vue -->
<template>
    <div>
        <!-- Your template for TitleSection -->
        <!-- <p>vocab section with ID: {{ section.id }}</p> -->
        <div>
            <div>
                <div class="css-1rj1g7w">
                    <div class="css-8e7ofr">
                        <div class="css-rvolo9">
                            <div class="css-1bvc4cc">
                            </div>
                        </div>
                        <div class="css-rvolo9">
                            <div v-for="(vocab_section_word, index) in section.vocab_section_words" :key="index"
                                class="css-1os9q1c">
                                <div class="css-15830to">
                                    <div class="css-nrgpft" data-mouseover-dictionary-disabled=""
                                        style="min-width: 25%; max-width: 25%;">
                                        <span class="css-l5xv05">
                                            <a href="/app/words/word/spread/zga0ELstQmCjlQAAAACmRg" class="css-1unrqcp"
                                                target="_blank">
                                                <span class="css-18tpl2u">
                                                    <span class="css-18tpl2u">
                                                        {{ getWordDisplay(vocab_section_word).word }}
                                                        <!-- {{ references_text }} -->
                                                    </span>
                                                </span>
                                            </a>
                                        </span>
                                        <span class="css-19a4ne0">
                                            <button type="button" class="css-1iy583d" aria-label="Play audio"
                                                @click="playAudio" v-if="getWordDisplay(vocab_section_word).soundurl"
                                                :data-url="getWordDisplay(vocab_section_word).soundurl.url"
                                                aria-disabled="false" style="outline: none;">
                                                <span class="css-187tw0e">
                                                    <svg width="100%" height="100%" viewBox="0 0 32 32"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        preserveAspectRatio="xMidYMid meet" aria-hidden="true">
                                                        <path fill="#b8b8b8"
                                                            d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z">
                                                        </path>
                                                        <path fill="#b8b8b8"
                                                            d="M17.157 23.157c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.534-2.534 2.534-6.658 0-9.192-0.586-0.586-0.586-1.536 0-2.121s1.535-0.586 2.121 0c3.704 3.704 3.704 9.731 0 13.435-0.293 0.293-0.677 0.439-1.061 0.439z">
                                                        </path>
                                                        <path fill="#b8b8b8"
                                                            d="M22.485 25.985c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 4.094-4.094 4.094-10.755 0-14.849-0.586-0.586-0.586-1.536 0-2.121s1.536-0.586 2.121 0c2.55 2.55 3.954 5.94 3.954 9.546s-1.404 6.996-3.954 9.546c-0.293 0.293-0.677 0.439-1.061 0.439v0z">
                                                        </path>
                                                    </svg>
                                                </span>
                                            </button>
                                        </span>
                                        <div class="css-1no1tp4">
                                            <span class="css-l5xv05">
                                                <span class="css-119fje0"> {{
                                                    getWordDisplay(vocab_section_word).part_of_speech
                                                }}</span>
                                            </span>
                                        </div>
                                        <div class="css-0">
                                            <div class="css-17wj48a">
                                                <div class="css-l5xv05">
                                                    <span class="css-17poqz3">
                                                        <span class="css-17poqz3">
                                                            <span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="css-285sxv">
                                        <div class="css-102jtxr">
                                            <div class="css-1chc5i5">
                                                <div class="css-1ij7jdz">
                                                    <span class="css-l5xv05">
                                                        <span class="css-csp2oc"
                                                            data-mouseover-dictionary-disabled="">{{
                                                                getWordDisplay(vocab_section_word).pronunciations
                                                            }}</span>
                                                    </span>
                                                </div>
                                                <div class="css-1ij7jdz">
                                                    <span class="css-l5xv05">
                                                        <span>{{
                                                            getWordDisplay(vocab_section_word).definition
                                                        }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div v-for="(vocab_section_word_sentence, index) in vocab_section_word.vocab_section_word_sentences"
                                                class="css-1uwkeis" :key="index">
                                                <div class="css-yqg3ok">
                                                    <div class="css-1rpivwb">
                                                        <div class="css-rvolo9">
                                                            <div>
                                                                <span class="css-l5xv05">
                                                                    {{
                                                                        getWordSentenseDisplay(vocab_section_word_sentence).text
                                                                    }}
                                                                </span>
                                                                <div class="css-1ftj09n">
                                                                    <button type="button" class="css-1iy583d"
                                                                        v-if="getWordSentenseDisplay(vocab_section_word_sentence).soundurl"
                                                                        aria-label="Play audio" aria-disabled="false"
                                                                        style="outline: none;"
                                                                        :data-url="getWordSentenseDisplay(vocab_section_word_sentence).soundurl.url"
                                                                        @click="playAudio">
                                                                        <span class="css-187tw0e">
                                                                            <svg width="100%" height="100%"
                                                                                viewBox="0 0 32 32"
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                preserveAspectRatio="xMidYMid meet"
                                                                                aria-hidden="true">
                                                                                <path fill="#b8b8b8"
                                                                                    d="M13 30c-0.26 0-0.516-0.102-0.707-0.293l-7.707-7.707h-3.586c-0.552 0-1-0.448-1-1v-10c0-0.552 0.448-1 1-1h3.586l7.707-7.707c0.286-0.286 0.716-0.372 1.090-0.217s0.617 0.519 0.617 0.924v26c0 0.404-0.244 0.769-0.617 0.924-0.124 0.051-0.254 0.076-0.383 0.076z">
                                                                                </path>
                                                                                <path fill="#b8b8b8"
                                                                                    d="M17.157 23.157c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 2.534-2.534 2.534-6.658 0-9.192-0.586-0.586-0.586-1.536 0-2.121s1.535-0.586 2.121 0c3.704 3.704 3.704 9.731 0 13.435-0.293 0.293-0.677 0.439-1.061 0.439z">
                                                                                </path>
                                                                                <path fill="#b8b8b8"
                                                                                    d="M22.485 25.985c-0.384 0-0.768-0.146-1.061-0.439-0.586-0.586-0.586-1.535 0-2.121 4.094-4.094 4.094-10.755 0-14.849-0.586-0.586-0.586-1.536 0-2.121s1.536-0.586 2.121 0c2.55 2.55 3.954 5.94 3.954 9.546s-1.404 6.996-3.954 9.546c-0.293 0.293-0.677 0.439-1.061 0.439v0z">
                                                                                </path>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="css-vo4u76">
                                                        <div class="css-nyr3n3">
                                                            <button type="button" class="css-4f999p"
                                                                data-testid="lesson-image" aria-label="Expand image"
                                                                style="outline: none;">
                                                                <div class="css-1rux0er">
                                                                    <img v-if="getWordSentenseDisplay(vocab_section_word_sentence).image"
                                                                        alt="" class="css-1n5bmmd"
                                                                        :src="getWordSentenseDisplay(vocab_section_word_sentence).image.url"
                                                                        loading="lazy">
                                                                    <!-- {{
                                                                        getWordSentenseDisplay(vocab_section_word_sentence).image.url
                                                                    }} -->
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="css-1vcgbrw" role="button" tabindex="0"
                                            aria-label="Show less sentences" aria-expanded="true">
                                            <svg fill="none" viewBox="0 0 24 24" width="1em" height="1em"
                                                aria-hidden="true" class="css-hcb1yg">
                                                <g fill="currentColor" clip-path="url(#:r3f:)">
                                                    <path
                                                        d="m19.17 6.86-8.2 7.37a1.84 1.84 0 0 0 2.46 2.73l8.2-7.37a1.84 1.84 0 1 0-2.46-2.73Z">
                                                    </path>
                                                    <path
                                                        d="m5.03 6.86 8.2 7.37a1.84 1.84 0 0 1-2.46 2.73L2.57 9.6a1.84 1.84 0 0 1 2.46-2.73Z">
                                                    </path>
                                                </g>
                                                <defs>
                                                    <clipPath id=":r3f:">
                                                        <path fill="#fff" d="M1.5 6h21v11.55h-21z">
                                                        </path>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: ['section', 'references'],
    data() {
        return {
            // references_text: ''
        }
    },
    computed: {
        references_word() {
            return (vocab_section_word) => {
                const wordRef = vocab_section_word.word ? vocab_section_word.word._ref : null;
                if (wordRef && this.references[wordRef]) {
                    const wordData = this.references[wordRef];
                    return {
                        word: wordData.word,
                        soundurl: wordData.sound,
                        part_of_speech: wordData.part_of_speech,
                        definition: wordData.definition,
                        pronunciations: wordData.pronunciations[0]
                    };
                } else if (vocab_section_word.local_word) {
                    return {
                        word: vocab_section_word.local_word.word,
                        soundurl: vocab_section_word.local_word.sound,
                        part_of_speech: vocab_section_word.local_word.part_of_speech,
                        definition: vocab_section_word.local_word.definition,
                        pronunciations: vocab_section_word.local_word.pronunciations[0]
                    };
                }
                return {};
            };
        },
        references_WordSentense() {
            return (vocab_section_WordSentense) => {
                const wordSentenceRef = vocab_section_WordSentense.word_sentence ? vocab_section_WordSentense.word_sentence._ref : null;
                if (wordSentenceRef && this.references[wordSentenceRef]) {
                    const sentenceData = this.references[wordSentenceRef].sentence;
                    const textData = this.references[sentenceData._ref];
                    return {
                        text: textData ? textData.text : '',
                        soundurl: textData ? textData.sound : '',
                        image: textData ? textData.image : ''
                    };
                } else if (vocab_section_WordSentense.local_sentence) {
                    return {
                        text: vocab_section_WordSentense.local_sentence.text,
                        soundurl: vocab_section_WordSentense.local_sentence.sound,
                        image: vocab_section_WordSentense.local_sentence.image
                    };

                }
                else if (vocab_section_WordSentense.global_sentence) {
                    let wordRef = vocab_section_WordSentense.global_sentence._ref;
                    console.log(wordRef)
                    const textData = this.references[wordRef];
                    // const textData = this.references[wordRef];
                    return {
                        text: textData ? textData.text : '',
                        soundurl: textData ? textData.sound : '',
                        image: textData ? textData.image : ''
                    };
                    // return {
                    //     text: vocab_section_WordSentense.local_sentence.text,
                    //     soundurl: vocab_section_WordSentense.local_sentence.sound,
                    //     image: vocab_section_WordSentense.local_sentence.image
                    // };

                }

                return {};
            };
        }
    }
    ,
    methods: {
        getWordDisplay(vocab_section_word) {
            return this.references_word(vocab_section_word);
        },
        getWordSentenseDisplay(vocab_section_WordSentense) {
            return this.references_WordSentense(vocab_section_WordSentense);
        }, playAudio(event) {
            const audioUrl = event.currentTarget.getAttribute('data-url');
            if (audioUrl) {
                const audio = new Audio(audioUrl);
                audio.play();
            }
        }
    }

};
</script>


<style scoped>
.css-1rj1g7w {
    background-color: white;
    padding: 4px;
    margin: 0px -4px;
    border-radius: 3px;
}

.css-8e7ofr {
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-flow: column;
    -webkit-box-pack: start;
    justify-content: flex-start;
    margin: calc(-5px) 0px;
}

.css-rvolo9 {
    margin: calc(5px) 0px;
}

.css-1os9q1c {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-top: -2px;
}

.css-15830to {
    display: flex;
    width: 100%;
}

.css-nrgpft:first-of-type {
    border-left: 2px solid rgb(242, 242, 242);
}

.css-285sxv:nth-of-type(2) {
    border-left: none;
}

.css-285sxv {
    vertical-align: top;
    padding: 15px;
    -webkit-box-flex: 1;
    flex-grow: 1;
    border: 2px solid rgb(242, 242, 242);
    box-sizing: border-box;
}

.css-102jtxr {
    padding-left: 15px;
    padding-bottom: 10px;
    font-size: 14px;
    color: rgb(109, 109, 109);
}

.css-1uwkeis {
    margin: 0px 0px 3px;
}

.css-yqg3ok {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    color: rgb(0, 0, 0);
    background-color: rgb(248, 248, 248);
    line-height: 1.5;
    padding: 10px 15px;
    min-height: calc(63px);
}

.css-1rpivwb {
    display: flex;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-flow: column;
    -webkit-box-pack: center;
    justify-content: center;
    margin: calc(-5px) 0px;
}

.css-vo4u76 {
    margin-left: 10px;
}

.css-nyr3n3 {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-flex: 0;
    flex-grow: 0;
    flex-shrink: 1;
}

.css-4f999p {
    display: block;
    border: none;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    text-align: center;
    appearance: none;
    position: relative;
}

.css-1iy583d {
    display: inline-block;
    border: 0px;
    padding: 0px;
    margin: 0px;
    text-decoration: none;
    background: 0px center;
    color: rgb(0, 157, 255);
    font-family: inherit;
    font-size: inherit;
    cursor: pointer;
    text-align: left;
    appearance: none;
    position: relative;
}

.css-19a4ne0 {
    flex-shrink: 0;
    margin-left: 5px;
    vertical-align: middle;
}

.css-187tw0e {
    display: inline-block;
    width: 15px;
    height: 15px;
}

.css-nrgpft:first-of-type {
    border-left: 2px solid rgb(242, 242, 242);
}

.css-1unrqcp {
    color: inherit;
    text-decoration: none;
}

.css-18tpl2u {
    color: rgb(255, 139, 0);
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 600;
}

.css-nrgpft {
    position: relative;
    overflow-wrap: break-word;
    hyphens: auto;
    border-bottom: 2px solid rgb(242, 242, 242);
    border-top: 2px solid rgb(242, 242, 242);
    box-sizing: border-box;
    line-height: 1.5;
    padding: 15px;
    vertical-align: top;
    background-image: url(data:image/svg+xml;charset=utf-8;base64,CiAgICA8c3ZnCiAgICAgIHdpZHRoPSIyIgogICAgICBoZWlnaHQ9IjExLjUiCiAgICAgIHZpZXdQb3J0PSIwIDAgMiAxMS41IgogICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgID4KICAgICAgPGxpbmUKICAgICAgICBzdHJva2U9IiNmMmYyZjIiCiAgICAgICAgc3Ryb2tlLXdpZHRoPSIyIgogICAgICAgIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIKICAgICAgICB4MT0iMSIgeDI9IjEiCiAgICAgICAgeTE9IjEiIHkyPSI2LjUiCiAgICAgIC8+CiAgICA8L3N2Zz4KICA=);
    background-position: right bottom 8px;
    background-repeat: repeat-y;
}

.css-1lr47bn {
    margin: 3px 0px;
    animation-name: animation-5j8bii;
    animation-duration: 500ms;
}

.css-1ykatrf {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    align-items: flex-start;
    color: rgb(0, 0, 0);
    background-color: rgb(248, 248, 248);
    line-height: 1.5;
    padding: 10px 15px;
}

.css-1n5bmmd {
    height: 48px;
    width: 48px;
    object-fit: cover;
    aspect-ratio: 1250 / 1082;
    border-radius: 3px;
    display: block;
}

.css-csp2oc {
    color: rgb(43, 172, 238);
    font-family: var(--kf-font-ipa-family);
    font-weight: var(--kf-font-weight-bold);
    line-height: var(--kf-font-line-height-m);
    letter-spacing: var(--kf-font-letter-spacing-m);
    margin: 0px 0.25em;
}
</style>