<!-- TitleSection.vue -->
<template>
    <div>
        <!-- Your template for TitleSection -->
        <!-- <p>Title Section with ID: {{ section.id }}</p>
        <p>Title Section with ID: </p> -->
        <div>
            <div>
                <div class="css-nbcxml">
                    <div class="css-h773az">
                        <div class="css-1sf241b">
                            <span class="css-0">
                                <span class="css-l5xv05">
                                    <span class="css-17poqz3" lang="en">
                                        <span>{{ this.references[section.title_text._ref].text }}</span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section', 'references'],
};
</script>

<style scoped>
.css-nbcxml {
    margin-top: var(--kf-static-space-xl);
}

.css-h773az {
    margin-bottom: 1.5em;
}

.css-1sf241b {
    line-height: 1;
    margin: 10px 0px;
    font-weight: 600;
    font-size: 20px;
}
</style>