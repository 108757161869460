<template>
    <div class="right-side">
        <!-- Chat Section -->
        <button class="btn-close-right">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" stroke="currentColor"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="feather feather-x-circle"
                viewBox="0 0 24 24">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M15 9l-6 6M9 9l6 6"></path>
            </svg>
        </button>
        <div class="chat-container">
            <div class="chat-header">
                <button class="chat-header-button">Live Chat</button>
            </div>
            <div class="chat-area" id="chat-area">
                <div v-for="message in chatMessages" :key="message.id">
                    <div :class="{ 'message-wrapper': true, 'reverse': message.isLocal }">
                        <div class="profile-picture">
                            <img :src="message.profilePicture" alt="">
                        </div>
                        <div class="message-content">
                            <p class="name">{{ message.sender }}</p>
                            <div class="message">{{ message.content }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="chat-typing-area-wrapper">
                <div class="chat-typing-area">
                    <input type="text" v-model="chatMessage" placeholder="Type your message..." class="chat-input"
                        id="messageInput" />
                    <button class="send-button" @click="sendChatMessage">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"
                            viewBox="0 0 24 24">
                            <path d="M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- Participants Section -->
        <div class="participants">
            <div class="participant profile-picture">
                <img src="https://images.unsplash.com/photo-1576110397661-64a019d88a98?ixlib=rb-1.2.1&auto=format&fit=crop&w=1234&q=80"
                    alt="" />
            </div>
            <div class="participant profile-picture">
                <img src="https://images.unsplash.com/photo-1566821582776-92b13ab46bb4?ixlib=rb-1.2.1&auto=format&fit=crop&w=900&q=60"
                    alt="" />
            </div>
            <div class="participant profile-picture">
                <img src="https://images.unsplash.com/photo-1600207438283-a5de6d9df13e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80"
                    alt="" />
            </div>
            <div class="participant profile-picture">
                <img src="https://images.unsplash.com/photo-1581824283135-0666cf353f35?ixlib=rb-1.2.1&auto=format&fit=crop&w=1276&q=80"
                    alt="" />
            </div>
            <div class="participant-more">2+</div>
        </div>
    </div>
    <button class="expand-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-message-circle">
            <path
                d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" />
        </svg>
    </button>
</template>

<script>
export default {
    props: ['socket', 'currentRoomId', 'userId'],
    data() {
        return {

            chatMessages: []
        }
    },
    methods: {
        sendChatMessage() {
            const message = this.chatMessage;
            this.socket.send(
                JSON.stringify({
                    type: "chat-message",
                    message,
                    roomId: this.currentRoomId,
                    userId: this.userId,

                })
            );
            this.chatMessages.push({
                content: message,
                sender: "You",
                isLocal: true,
                profilePicture: "https://st3.depositphotos.com/15648834/17930/v/1600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"

            });
            this.chatMessage = "";
        },
        displayChatMessage(message, userId) {
            const sender = userId == this.userId ? "You" : `User ${userId}`;
            this.chatMessages.push({
                content: message,
                sender,
                isLocal: userId === this.userId,
                profilePicture: "https://st3.depositphotos.com/15648834/17930/v/1600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
            });
        },
    }
}
</script>

<style scoped></style>