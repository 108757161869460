<!-- TitleSection.vue -->
<template>
    <div>
        <!-- Your template for TitleSection -->
        <!-- <p>Question Section with ID: {{ section.id }}</p> -->
        <div>
            <div>
                <div>
                    <div v-for="(question, index) in section.questions" :key="index">
                        <div class="css-18xwsgn">
                            <div style="display: flex; align-items: baseline;">
                                <div class="css-1yzarxl">
                                    <span>{{ index + 1 }}.</span>
                                </div>
                                <div class="css-kbrqek">
                                    <div class="css-19rokqe">
                                        <div>
                                            <div>
                                                <span class="css-l5xv05">
                                                    <span class="css-17poqz3" lang="en">
                                                        {{ question.text.text }}
                                                    </span>
                                                </span>
                                                <div v-if="question.quiz_type == 'MultiChoice'"
                                                    style="margin-top: 15px;">
                                                    <ol style="margin: 0px; padding-left: 0px; list-style-type: none;">
                                                        <li v-for="(answer, index) in question.answers" :key="index">
                                                            <div style="display: flex; align-items: baseline;">
                                                                <div class="css-1yzarxl">
                                                                    <span
                                                                        style="font-size: 0.8em; font-family: var(--kf-font-family-mono); color: var(--kf-color-grey-600);">{{ indexToUppercase(index) }}.</span>
                                                                </div>
                                                                <div class="css-kbrqek">
                                                                    <div class="css-1kuy7z7">
                                                                        <span class="css-l5xv05">
                                                                            <span class="css-17poqz3" lang="en">
                                                                                <span>{{ answer.text.text }}</span>
                                                                            </span>
                                                                        </span>
                                                                        <div class="css-fm8mu8">
                                                                            <span class="css-17poqz3">
                                                                                <span>

                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>

                                                    </ol>
                                                </div>
                                                <div>
                                                    <span class="css-17poqz3">
                                                        <span>

                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="css-5fo5ce">

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section'],
    methods: {

        indexToUppercase(index) {
            return String.fromCharCode(65 + index);
        }


    }

};
</script>

<style scoped>
.css-18xwsgn {
    margin-top: 40px;
}

.css-1yzarxl {
    margin: 0px 0.5em;
    cursor: default;
    user-select: none;
    flex-shrink: 0;
}

.css-kbrqek {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 100%;
}

.css-5fo5ce {
    margin-top: 2em;
}

.css-19rokqe {
    min-height: 1.7em;
    font-size: 18px;
}

.css-1kuy7z7 {
    font-size: 14px;
}
</style>