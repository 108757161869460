<template>
    <div>

        <!-- <p>Aside Section with ID: {{ section.id }}</p> -->
        <div>
            <div>
                <div class="css-1tx38fa">
                    <div class="css-1rj1g7w">
                        <div class="css-14y62k7">
                            <span class="css-l5xv05">
                                <span class="css-17poqz3" lang="en">
                                    <span>
                                        <!-- {{ section.aside_text.text }} -->
                                        <!-- {{ section.aside_text.text }} -->
                                        {{ asideText }}

                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['section'],
    data() {
        return {
            asideTextContent: ''
        };
    },
    computed: {
        asideText() {
            if (this.section.aside_text._ref) {
                this.getAsideTextFromParent(this.section.aside_text._ref);
                return this.asideTextContent;
            } else {
                return this.section.aside_text.text;
            }
        }
    },
    methods: {
        getAsideTextFromParent(ref) {
            this.$emit('handleParentMethod', ref, (result) => {
                // console.log("this.result :", result)

                this.asideTextContent = result.text;
            });
        }
    }
}
</script>

<style scoped>
.css-1tx38fa {
    margin: 10px 0px;
}

.css-1rj1g7w {
    background-color: white;
    padding: 4px;
    margin: 0px -4px;
    border-radius: 3px;
}

.css-14y62k7 {
    padding: 15px 25px;
    border-radius: 3px;
    font-size: 14px;
    background-color: rgb(233, 249, 243);
}

.css-l5xv05 {
    position: relative;
}
</style>