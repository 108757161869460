<template>
    <div class="study-room app-container" ref="studyRoom">
        <!-- <h1>Study Room</h1> -->
        <VideoCall v-if="socket" :socket="socket" :currentRoomId="currentRoomId" :userId="userId" ref="VideoCallRef" />
        <Chat_Section v-if="socket" :socket="socket" :currentRoomId="currentRoomId" :userId="userId"
            ref="Chat_SectionRef" />
    </div>
    <!-- <MaterialView /> -->

    <div @mouseup="onMouseUp" @mousedown="onMouseDown">
        <keep-alive>
            <router-view v-if="socket" :socket="socket" :currentRoomId="currentRoomId" :userId="userId" /></keep-alive>
    </div>

</template>

<script>
// import '@/assets/style.css';
import VideoCall from '@/components/VideoCall.vue';
import Chat_Section from '@/components/Chat_Section.vue';
// import MaterialView from '@/views/MaterialView.vue';
// import Material_View from './materials/CategorizeView.vue';

export default {
    data() {
        return {
            socket: null, userId: this.generateUserId(), currentRoomId: null, storedRange: null, color: this.getUserColor(), warningTag: null  // Track the warning tag
        }
    },
    components: {
        VideoCall,
        Chat_Section,
        // MaterialView

    },
    mounted() {
        // this.initializeMedia();
        this.initializeWebSocket();
        // document.addEventListener('mouseup', this.onMouseUp);
        // document.addEventListener('mousedown', this.onMouseDown);
        window.addEventListener('resize', this.updateSelection);
    },
    beforeUnmount() {
        // this.socket.close();
        // document.removeEventListener('mouseup', this.onMouseUp);
        // document.removeEventListener('mousedown', this.onMouseDown);
        window.removeEventListener('resize', this.updateSelection);
    },

    created() {


        this.$watch('$route', (to) => {
            if (to.path.startsWith('/study-room/')) {
                this.handleNavigation(to.path);
            }
        });
    },


    methods: {
        async initializeWebSocket() {
            try {
                // this.socket = new WebSocket("wss://realvideo.usmlepros.com/server");
                // this.socket = new WebSocket("ws://localhost:5050");
                this.socket = new WebSocket("wss://leaqo.com/webso");

                this.socket.onopen = () => {
                    console.log("WebSocket connection opened");
                    this.joinRoomIfAvailable();
                };
                this.socket.onmessage = (event) => {
                    // console.log("WebSocket message received:", event);
                    this.handleSocketMessage(event);
                };
                this.socket.onerror = (error) => {
                    console.error("WebSocket error:", error);
                };
                this.socket.onclose = () => {
                    console.log("WebSocket connection closed");
                };
            } catch (error) {
                console.error("Failed to initialize WebSocket", error);
            }
        },

        joinRoomIfAvailable() {
            const roomId =
                new URLSearchParams(window.location.search).get("room_id") ||
                localStorage.getItem("roomId");
            if (roomId) {
                this.joinRoom(roomId);
            } else {
                console.log("No room ID available");
            }
        },
        joinRoom(roomId) {
            this.currentRoomId = roomId;
            localStorage.setItem("roomId", roomId);
            console.log(`Joining room ${roomId}`, " As user :", this.userId);
            this.socket.send(
                JSON.stringify({ type: "join-room", roomId, userId: this.userId })
            );
        },
        handleSocketMessage(event) {
            if (event.data instanceof Blob) {
                const reader = new FileReader();
                reader.onload = () => {
                    const data = JSON.parse(reader.result);
                    this.processSocketMessage(data);
                };
                reader.readAsText(event.data);
            } else {
                const data = JSON.parse(event.data);
                this.processSocketMessage(data);
            }
        },

        processSocketMessage(data) {
            // console.log("Handling socket message:", data);
            // this.$refs.VideoCallRef.setLocalStream();

            switch (data.type) {

                case "navigate":
                    this.clearSelection()
                    this.$router.push(data.route);
                    break;
                case "highlight":
                    this.removeWarningTag();
                    this.clearSelection();
                    this.recreateSelection(data);

                    break;
                case "clear":
                    this.clearSelection();

                    break;
                case "chat-message":

                    this.$refs.Chat_SectionRef.displayChatMessage(data.message, data.userId);
                    break;
                default:
                    console.log("Unknown message type:", data.type);
            }
        },
        generateUserId() {
            return "user_" + Math.random().toString(36).substr(2, 9);
        },
        handleNavigation(route) {
            this.clearSelection()
            this.socket.send(JSON.stringify({
                type: 'navigate', route,
                roomId: this.currentRoomId,
                userId: this.userId
            }));
        }



        ,
        //hihlite
        getUserColor() {
            const colors = [
                'rgba(255, 0, 0, 0.5)',    // Red
                'rgba(0, 255, 0, 0.5)',    // Green
                'rgba(0, 0, 255, 0.5)',    // Blue
                'rgba(255, 255, 0, 0.5)',  // Yellow
                'rgba(0, 255, 255, 0.5)',  // Cyan
                'rgba(255, 0, 255, 0.5)',  // Magenta
                'rgba(128, 0, 0, 0.5)',    // Maroon
                'rgba(0, 128, 0, 0.5)',    // Dark Green
                'rgba(0, 0, 128, 0.5)',    // Navy
                'rgba(128, 128, 0, 0.5)'   // Olive
            ];
            const randomIndex = Math.floor(Math.random() * colors.length);
            return colors[randomIndex];
        },

        onMouseUp() {
            this.removeWarningTag();
            document.querySelectorAll('.css-1isip6o').forEach(el => el.remove());
            this.storedRange = null;
            this.socket.send(JSON.stringify({
                type: 'clear',
                roomId: this.currentRoomId,
                userId: this.userId
            }));
            let selection = window.getSelection();
            if (selection.rangeCount > 0) {
                // console.log("asdf");
                this.storedRange = selection.getRangeAt(0);
                if (!this.storedRange.collapsed) {
                    this.updateSelection();
                }
            }
        },
        onMouseDown() {
            // document.querySelectorAll('.css-1isip6o').forEach(el => el.remove());
            // this.storedRange = null;
            // this.socket.send(JSON.stringify({
            //     type: 'clear',
            //     roomId: this.currentRoomId,
            //     userId: this.userId
            // }));
        },
        updateSelection() {
            // Clear previous highlights
            document.querySelectorAll('.css-1isip6o').forEach(el => el.remove());

            if (!this.storedRange) return;
            let rects = this.storedRange.getClientRects();
            let rectData = Array.from(rects).map(rect => ({
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
                height: rect.height
            }));

            const range = this.storedRange;
            const data = {
                type: 'highlight',
                rects: rectData,
                startContainerXPath: this.getXPath(range.startContainer),
                startOffset: range.startOffset,
                endContainerXPath: this.getXPath(range.endContainer),
                endOffset: range.endOffset,
                color: this.color,
                roomId: this.currentRoomId,
                userId: this.userId
            };
            // console.log("asdfff")

            this.socket.send(JSON.stringify(data));
            this.highlightSelection(rectData, this.color);
            this.storedRange = null;
        },
        updateSelections(remote_color) {
            // Clear previous highlights
            document.querySelectorAll('.css-1isip6o').forEach(el => el.remove());

            if (!this.storedRange) return;

            let rects = this.storedRange.getClientRects();
            let rectData = Array.from(rects).map(rect => ({
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX,
                width: rect.width,
                height: rect.height
            }));
            this.highlightSelection(rectData, remote_color);
            window.getSelection().removeAllRanges();
            this.storedRange = null;
        },
        // highlightSelection(rects, color) {
        //     rects.forEach(rect => {
        //         let div = document.createElement('div');
        //         div.className = 'css-1isip6o';
        //         div.style.top = `${rect.top}px`;
        //         div.style.left = `${rect.left}px`;
        //         div.style.width = `${rect.width}px`;
        //         div.style.height = `${rect.height}px`;
        //         div.style.backgroundColor = color || 'rgba(128, 0, 255, 0.5)'; // Default color if not specified

        //         document.body.appendChild(div);
        //     });
        // },
        highlightSelection(rects, color) {
            let isOutsideViewport = false;
            let firstRect = null;

            rects.forEach((rect, index) => {
                let div = document.createElement('div');
                div.className = 'css-1isip6o';
                div.style.top = `${rect.top}px`;
                div.style.left = `${rect.left}px`;
                div.style.width = `${rect.width}px`;
                div.style.height = `${rect.height}px`;
                div.style.backgroundColor = color || 'rgba(128, 0, 255, 0.5)'; // Default color if not specified
                document.body.appendChild(div);

                // Capture the first rectangle for scroll detection
                if (index === 0) firstRect = rect;

                // Check if the rect is outside the viewport
                const viewportHeight = window.innerHeight;
                const viewportTop = window.scrollY;
                const viewportBottom = viewportTop + viewportHeight;

                // If the rect is outside the visible viewport, set a flag
                if (rect.top < viewportTop || rect.top + rect.height > viewportBottom) {
                    isOutsideViewport = true;
                }
            });

            // Show the warning tag if selection is outside the visible area
            if (isOutsideViewport) {
                this.showWarningTag(firstRect, color);  // Pass the first rectangle for positioning
            }
        }
        ,
        // Method to show the warning tag at the top or bottom
        showWarningTag(firstRect, color) {
            const viewportTop = window.scrollY;
            const viewportBottom = viewportTop + window.innerHeight;

            // Remove any existing warning tag before creating a new one
            this.removeWarningTag();

            let tag = document.createElement('div');
            tag.className = 'highlight-warning-tag';
            tag.innerText = "highlighted text out of view";  // Example warning message
            // tag.style.position = 'fixed';
            // tag.style.left = '50%';
            // tag.style.transform = 'translateX(-50%)';
            tag.style.backgroundColor = color;
            // tag.style.padding = '5px';
            // tag.style.border = '1px solid black';
            // tag.style.borderRadius = '3px';
            // tag.style.zIndex = 1000;

            // Place the warning tag at the top or bottom of the viewport
            if (firstRect.top < viewportTop) {
                // Highlight is above the viewport, show tag at the top
                tag.style.top = '0px';
                tag.style.bottom = 'auto';
            } else if (firstRect.top + firstRect.height > viewportBottom) {
                // Highlight is below the viewport, show tag at the bottom
                tag.style.top = 'auto';
                tag.style.bottom = '0px';
            }

            document.body.appendChild(tag);

            // Store the tag reference for later removal
            this.warningTag = tag;

            // Remove the tag when clicked
            tag.addEventListener('click', () => {
                this.removeWarningTag();
                window.removeEventListener('scroll', handleScroll);
            });

            // Scroll listener to remove the tag when the selection is in view
            const handleScroll = () => {
                const currentViewportTop = window.scrollY;
                const currentViewportBottom = currentViewportTop + window.innerHeight;

                // Check if the highlight is now in the visible viewport
                if (firstRect.top >= currentViewportTop && (firstRect.top + firstRect.height) <= currentViewportBottom) {
                    this.removeWarningTag();  // Remove the tag
                    window.removeEventListener('scroll', handleScroll);  // Remove the scroll listener
                }
            };

            window.addEventListener('scroll', handleScroll);
        }
        ,
        // Method to remove the warning tag
        removeWarningTag() {
            if (this.warningTag) {
                document.body.removeChild(this.warningTag);
                this.warningTag = null;
            }
        }


        , recreateSelection(data) {
            const range = document.createRange();
            const startContainer = this.getElementByXPath(data.startContainerXPath);
            const endContainer = this.getElementByXPath(data.endContainerXPath);
            if (startContainer && endContainer) {
                range.setStart(startContainer, data.startOffset);
                range.setEnd(endContainer, data.endOffset);

                const selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                if (selection.rangeCount > 0) {
                    this.storedRange = selection.getRangeAt(0);
                    if (!this.storedRange.collapsed) {
                        this.updateSelections(data.color);
                    }
                }
            }
        },
        clearSelection() {
            document.querySelectorAll('.css-1isip6o').forEach(el => el.remove());
            window.getSelection().removeAllRanges();
        },
        getXPath(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                return this.getXPath(node.parentNode) + '/text()';
            }
            if (node.id !== '') {
                return 'id("' + node.id + '")';
            }
            if (node === document.body) {
                return '/html/body';
            }

            let ix = 0;
            const siblings = node.parentNode.childNodes;
            for (let i = 0; i < siblings.length; i++) {
                const sibling = siblings[i];
                if (sibling === node) {
                    return this.getXPath(node.parentNode) + '/' + node.tagName.toLowerCase() + '[' + (ix + 1) + ']';
                }
                if (sibling.nodeType === 1 && sibling.tagName === node.tagName) {
                    ix++;
                }
            }
        },
        getElementByXPath(xpath) {
            const evaluator = new XPathEvaluator();
            const result = evaluator.evaluate(
                xpath,
                document.documentElement,
                null,
                XPathResult.FIRST_ORDERED_NODE_TYPE,
                null
            );
            return result.singleNodeValue;
        },


    }
}
</script>

<style src="../assets/style.css"></style>
<style>
.highlight-warning-tag {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    /* background-color: rgb(255 195 0 / 50%); */
    padding: 5px;
    border-radius: 3px;
    z-index: 1000;
    bottom: 0px;
    text-align: center;
}
</style>