<!-- TeacherNoteSection.vue -->
<template>
    <div>
        <!-- Your template for TeacherNoteSection -->
        <p>Teacher Note Section with ID: {{ section.id }}</p>
    </div>
</template>

<script>
export default {
    props: ['section'],
};
</script>